import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
// import { FixedSizeList as FixedSizeList } from 'react-window';
import {List as FixedSizeList } from 'react-virtualized';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setLeftSearchVal, setOpenBoothList, setLeftPage, setOpenSetBoothModal } from '../../../actions/ui'
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Button from '@mui/material/Button';
import CompanyEditModal from '../CompanyEditModal'
import { useEffect,useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import  area  from 'area-polygon'
import { setActiveObject, px2unit, unit2Px, setActiveObjectsbyGroup, setDeactiveObjectsbyGroup } from '../../../utils/CanvasTool'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import { getTokenCookie } from '../../../utils/Common'
import { API_URL, S3_URL, ESHOW_URL } from '../../../config.js'
import Fade from '@mui/material/Fade';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function BoothList(props) {
  const theme = useTheme();
  const [boothList, setBoothList] = React.useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedGroup, setSelectedGroup] = React.useState(false);
  
  const [editBooth, setEditBooth]  = React.useState(null);
  const main_widow_offset_x = 0;
  const main_widow_offset_y = 51;
  var mainCanvas = props.main.mainCanvas
  
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if(props.ui.openBoothList){
      setLoadingFlag(true)
      
      var active_booth_file = props.main.active_booth_file
      var url = `${ESHOW_URL()}?method=getBoothGroups&map_key=${active_booth_file?.KEY_ID}&token=${getTokenCookie()}`
      var formdata = 
      {  
          query:`${ESHOW_URL()}?method=getBoothGroups&map_key=${active_booth_file?.KEY_ID}&token=${getTokenCookie()}`                
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
      });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      axiosInstance.get(url).then(res=>{
          const result = res.data;
          if(result.length > 0){
            for(var i = 0; i < result.length; i++){
                result[i].TITLE = result[i].TITLE.trim()
            }
          }
          result.sort(function (a, b) {
            return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
          });

          // var filteredBoothList = result.filter((booth)=>{
          //   return (booth.QTY_BOOTHS && booth.QTY_BOOTHS > 0)
          // })
          setFilteredBoothList(result)
          setBoothList(result)
          setLoadingFlag(false)
      }).catch(error => {
        setLoadingFlag(false)
      });  
    }
    return () => {
      if(selectedGroup){
        setDeactiveObjectsbyGroup(selectedGroup, props.main);
      }
    };
  }, [props.ui.openBoothList,props.main.canvasRefresh])


  const doSearch = (searchVal)=>{
    if(searchVal){
      var filteredBoothList = boothList.filter((booth)=>{
        var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setFilteredBoothList(filteredBoothList)
    }
    else
      setFilteredBoothList(boothList)
  }



  const getGroupBySize = (filteredBoothList)=>{
    let groupBySize = filteredBoothList.reduce((r, a) => {
      r[a.boothsize] = [...r[a.boothsize] || [], a];
      return r;
     }, {});
    var output = [];
    for(var boothsize in groupBySize){
      output.push({boothsize, items: groupBySize[boothsize]})
    }
    console.log("groupBySize", output)
    return output;
  }
  var list = [
  ];
  for(var i = 0; i < 5000; i++){
    list.push(i)
  }
  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    var booth = filteredBoothList[index]
    if(booth){
      return (
        <ListItem key={key} style={style}
          onClick={()=>{
            if(selectedGroup){
              setDeactiveObjectsbyGroup(selectedGroup, props.main)
            }
            setActiveObjectsbyGroup(booth.KEY_ID, props.main); 
            setSelectedGroup(booth.KEY_ID)}
          }
          sx={{cursor:'pointer'}}         
        >
          <ListItemText 
            primary={
              <Box display="flex" style={{justifyContent:'space-between'}}>
                <Box style={{width: '80%', textAlign:'left'}}><label>{booth.TITLE}</label></Box>
                <Box style={{width: '20%', textAlign:'left'}}><label>{(booth.QTY_BOOTHS?booth.QTY_BOOTHS:0)}</label></Box>
              </Box>
            } 
          />
          
        </ListItem>
        // <ListItem key={key} style={style}>
        //   123
        // </ListItem>
        // <ListItem key={key} style={style}
        //   // onClick={()=>{setActiveObject(booth.booth, props.main); props.setActive3dObject(booth.booth)}}
        //   // sx={{cursor:'pointer'}}
        // >
        //   123
          
        // </ListItem>
      );
    }
    else{
      return (<></>)
    }
  }

  console.log("booth list")
  var listHeight = window.innerHeight - 195
  const drawerWidth = Math.min(Math.max(410, Math.round(window.innerWidth*0.33)), window.innerWidth);
  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box className="">
          <Box display="flex" style={{alignItems:'center', justifyContent:'space-between'}} pt={2}  pb={2}>
            
            <div style={{marginLeft:20}}></div>
            <Box textAlign="left">
              <Box display="flex">
                <h4 style={{textTransform:'capitalize', margin:0}}>
                  Booth Groups
                </h4> 
              </Box>
            </Box>
            <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
              {props.role == 'admin' ?          
              <IconButton
                  size="small"
                  edge="start"
                  aria-label="assign"
                  style={{float:'right'}}
                  onClick={()=>{props.setOpenBoothList(false)}}
                >
                  <HighlightOffOutlinedIcon/>
              </IconButton>:
              <IconButton
                size="small"
                edge="start"
                aria-label="assign"
                style={{float:'right'}}
                onClick={()=>{props.setLeftPage('home')}}
              >
                <ChevronLeftIcon/>
              </IconButton>
              }
            </div>
          </Box>
            <Divider />
              <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 48px 10px 16px', borderBottom:'1px solid #e0e0e0', background:(props.ui?.customStyles?.['left-table-header-bg']?props.ui?.customStyles['left-table-header-bg']:'#4e7090'), color:'white', alignItems:'center'}}>
                <Box style={{width: '80%', textAlign:'left', paddingLeft: '0px'}}><b>Title</b></Box>
                <Box style={{width: '20%', textAlign:'center', paddingLeft: '0px'}}><b><LocationOnOutlinedIcon/></b></Box>
              </Box>
              {filteredBoothList&&
              <FixedSizeList
                width={(drawerWidth-(props.role == 'admin'?0:72))}
                height={listHeight}
                rowCount={filteredBoothList.length}
                rowHeight={48}
                rowRenderer={rowRenderer}
              >
              </FixedSizeList>
              }
              {/* {filteredBoothList&&
              <List>
                {
                    filteredBoothList.map((booth, booth_index) => (
                    <ListItem key={booth_index}
                      onClick={()=>{setActiveObject(booth.booth, props.main); props.setActive3dObject(booth.booth)}}
                      sx={{cursor:'pointer'}}
                    >
                      <ListItemText 
                        primary={
                          <Box display="flex" style={{justifyContent:'space-between'}}>
                            <Box style={{width: '40%', textAlign:'left'}}><label>{booth.name}</label></Box>
                            <Box style={{width: '30%', textAlign:'left'}}><label>{(booth.boothsize)}</label></Box>
                            <Box style={{width: '30%', textAlign:'center'}}>{booth.sqrt}</Box>
                          </Box>
                        } 
                      />
                      
                    </ListItem>
                  ))
                }
              </List>
              } */}
            
            <LoadingOverlay
                active={loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'absolute',
                    left:'50%',
                    top:'200px',
                    transform:'translateX(-50%)',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </Box>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setOpenSetBoothModal:  data=>dispatch(setOpenSetBoothModal(data)),
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
