import * as React from 'react';
import { useEffect,useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setOpenCompanyProfile, setLeftBoothList, setBookmardUpdated, setOpenLogin, setLeftSelectedCategory, setLeftPage, setOpenCompanyModal, setEditCompany, setSnackMsg, setOpenSnack } from '../../actions/ui'
import { setBookmarkList } from '../../actions/main'
import Chip from '@mui/material/Chip';
import SliderCarousel from './SliderCarousel'
import Carousel from "react-multi-carousel";
import ReactPlayer from 'react-player'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import VideocamIcon from '@mui/icons-material/Videocam';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, redrawobjectborder, adjustobjectborder, checkifrotate, checkifrotatepoints, checkiftable, rotatePathObject, rotateObject, rotatePoints, checkifpathrotate, checkifbooth, compressJson, toggleDrawMode, unlockMap, lockMap, addText, centerFocus, redrawRulers, changeBorderColor, getGeometry, setObjectBG, getBGColor, checkIfSelectable, composeLoadObjects, initCanvas, afterCanvasLoad, px2unit, unit2Px, renderCoordinate, removeCoordinate, moveCoordinate, setObjectBorder} from '../../utils/CanvasTool'
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import  area  from 'area-polygon'
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { parseToken, assetUrl } from '../../utils/Common'
import { getTokenCookie, getTokenPlanner } from '../../utils/Common'
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import Fade from '@mui/material/Fade';
import ShowMore from 'react-show-more';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FeaturedLabel from './FeaturedLabel';

import CloseIcon from '@mui/icons-material/Close';
const moment = require('moment-timezone');

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2.5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));


var booth;
var open;
var boothName = ''
var companyName;
var company_main_category;
var company_sub_category_displays;
var company_sub_category_podiums;
var company_sub_category_laptops;
var company_log_url;
var company_header;
var company_description;
var company_photos;
var company_videos;
var company_contact_street;
var company_contact_address2;
var company_contact_city;
var company_contact_state;
var company_contact_zip;
var company_contact_country;
var company_contact_fn;
var company_contact_ln;
var company_contact_phone_ext;
var company_contact_phone;
var company_facebook;
var company_twitter;
var company_snapchat;
var company_instagram
var company_linkdin;
var company_tiktok;
var company_call_link;
var company_call_label;
var company_website;
var toll_free;
var cell_phone;
var brand_name;
var company_name;
var fax;
var division;
var c_title;
var credentials;
var company_headerimg_url;
var company_bgimg_url;
var company_contact_email;
var company_tag_featured;
var company_tag_member;
var company_tag_3dbooth;
var bookmark
var company_tag_sponser;
var company_tag_level;
var company_category;
var companyTextFlag = false;
var backgroundColor = 'transparent'
var bgColor = "#dadada"

function Index(props) {
  const [tpage, setTpage]  = useState('');
  const [loaded, setLoaded] = useState(false)
  const [zIndex, setzIndex] = React.useState(1004);
  const [bookmark, setBookmark] = React.useState(false);
  const [exhibitor, setExhibitor] = React.useState(false);
  const [openProductDetails, setOpenProductDetails] = React.useState(false);
  const [openSpecialDetails, setOpenSpecialDetails] = React.useState(false);
  const [openReleaseDetails, setOpenReleaseDetails] = React.useState(false);
  const [openEventsDetails, setOpenEventsDetails] = React.useState(false);  
  const [openCallAction, setOpenCallAction] = React.useState(false);
  const [callFormValues, setCallFormValues] = React.useState(false);
  const [co_companies, setCo_companies] = React.useState(false);
  const scrollRef = useRef(null);
  
  

  var mainCanvas = props.main.mainCanvas
  var g_groupOptions = props.main.groupOptions

  const theme = useTheme();
  let query = useQuery();
  const getExhibitor = async (KEY_ID)=>{
    var result = null
    var token = false
    try{
      token =  getTokenPlanner();
      if(props.role=='admin'){
        token = getTokenCookie()
      }
      else{
        if(!token){
          token = getTokenCookie()
        }
      }
    }
    catch(e){

    }
    let booth = props.ui.openCompanyProfile.booth;
    var url = `${ESHOW_URL()}?method=getExhibitor&EXHIBITOR_KEY=${KEY_ID}&token=${token}&booth_key=${booth?.KEY_ID}`
    const response = await axiosInstance.get(url);
    if(response.status == 200){
      if(response.data?.SUCCESS == true){
        result = response.data?.EXHIBITOR;
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(response.data?.MESSAGE?response.data?.MESSAGE:"Something went wrong");
      }
        
    }
    else{
      props.setOpenSnack(true);
      props.setSnackMsg("Something went wrong");
    }
    return result

 }

  booth = props.ui.openCompanyProfile.booth;
  open = props.ui.openCompanyProfile.open;

  useEffect(async () => {
    if(props.ui.openCompanyProfile && props.ui.openCompanyProfile.open && Boolean(props.ui.mouseHoverEnable)){
      setzIndex(10000)
      var tokenInfo = await parseToken(query)
      var tpage = tokenInfo.SALES?.EXHIBITOR_URL?tokenInfo.SALES?.EXHIBITOR_URL:'';
      var booth = props.ui.openCompanyProfile.booth
      var exhibitors = booth?.exhibitors
      var company_KEY_ID
      if(exhibitors && exhibitors.length > 0){
        company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
      }
      if(company_KEY_ID && tpage){
        tpage = tpage.replace("{{exhibitor_key}}", company_KEY_ID)
      }
      setTpage(tpage)
      fetchBookmarkList();
      
      initForm()
      
    }
    else{
      setzIndex(1004)
    }
  }, [props.ui.openCompanyProfile])
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key')     
  
  useEffect(() => {
    if(props.ui.openCompanyProfile && props.ui.openCompanyProfile.open && Boolean(props.ui.mouseHoverEnable)){
      initForm()
    }
  }, [props.main[`planner-${show_key}`, props.ui.exhibitorUpdated]])

  
  useEffect(() => {
    if(props.ui.openCompanyProfile && props.ui.openCompanyProfile.open && Boolean(props.ui.mouseHoverEnable)){
      if(props.ui.exhibitorUpdated)
        initForm()
      }
  }, [props.ui.exhibitorUpdated])

  const resetForm = ()=>{
    company_videos = null
    company_contact_street = null;
    company_contact_address2 =null;
    company_contact_city = null;
    company_contact_state = null;
    company_contact_zip = null;
    company_contact_country = null;
    company_contact_fn = null;
    company_contact_ln = null;
    company_contact_phone = null;
    company_contact_phone_ext = null;
    company_facebook= null;
    company_twitter = null;
    company_snapchat = null;
    company_instagram = null;          
    company_linkdin = null;
    company_tiktok = null;
    company_call_label = null;
    company_call_link = null;
    company_website = null;
    toll_free = null;
    cell_phone = null
    brand_name = null;
    company_name = null;
    fax = null;
    division = null;
    c_title = null;
    credentials = null;
    company_headerimg_url = null;
    company_bgimg_url = null;
    company_tag_featured = null;
    company_tag_member = null;
    company_tag_3dbooth = null;
    company_tag_sponser = null;
    company_tag_level = null;
    setLoaded(false)
  }
  const initForm = async()=>{
    

    if(props.ui.openCompanyProfile && props.ui.openCompanyProfile.open && Boolean(props.ui.mouseHoverEnable)){
      booth = props.ui.openCompanyProfile.booth;
      open = props.ui.openCompanyProfile.open;
      if(booth.booth_number)
        boothName = booth.booth_number
      if(booth?.exhibitors){
        
        var exhibitors = booth?.exhibitors
        var company_KEY_ID
        if(exhibitors && exhibitors.length > 0){
          company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
        }
        if(company_KEY_ID){
          setLoaded(false)
          var exhibitorInfo = await getExhibitor(company_KEY_ID)
          setExhibitor(exhibitorInfo)

          setCo_companies(exhibitorInfo?.SUBEXHIBITORS)
          companyName = exhibitorInfo?.['DIRECTORY']['COMPANY_NAME']
          var CATEGORY = exhibitorInfo?.['DIRECTORY']['CATEGORY'];
          company_category = CATEGORY
          var tmp = []
          if(CATEGORY && CATEGORY.length){
            for(var i = 0; i < CATEGORY.length; i++){
              tmp.push(CATEGORY[i]['ANSWER_KEY']);
            }
          }
          // company_category = tmp
          company_log_url = assetUrl(exhibitorInfo?.['DIRECTORY']['LOGO']);
          company_description = exhibitorInfo?.['DIRECTORY']['DESCRIPTION'];
          company_header = exhibitorInfo?.['HEADER']
          var photos = exhibitorInfo?.['DIRECTORY']['PHOTOS'];
          var photoUrls = []
          if(photos && photos.length > 0){
            for(var i = 0; i < photos.length; i++){
              if(photos[i].URL)
                photoUrls.push(assetUrl(photos[i].URL))
            }
          }
          company_photos = photoUrls;
          var videos = exhibitorInfo?.['DIRECTORY']['VIDEOS'];
          var videoUrls = []
          if(videos && videos.length > 0){
            for(var i = 0; i < videos.length; i++){
              // if(videos[i].URL)
              videoUrls.push(videos[i])
            }
          }
          company_videos = videoUrls
          company_contact_street = exhibitorInfo?.['DIRECTORY']['ADDRESS1'];
          company_contact_address2 = exhibitorInfo?.['DIRECTORY']['ADDRESS2'];
          company_contact_city = exhibitorInfo?.['DIRECTORY']['CITY'];
          company_contact_state = exhibitorInfo?.['DIRECTORY']['STATE'];
          company_contact_zip = exhibitorInfo?.['DIRECTORY']['ZIP_CODE'];
          company_contact_country = exhibitorInfo?.['DIRECTORY']['COUNTRY'];
          company_contact_fn = exhibitorInfo?.['DIRECTORY']['FIRST_NAME'];
          company_contact_ln = exhibitorInfo?.['DIRECTORY']['LAST_NAME'];
          company_contact_phone = exhibitorInfo?.['DIRECTORY']['PHONE'];
          company_contact_phone_ext = exhibitorInfo?.['DIRECTORY']['PHONE_EXT'];
          company_facebook= exhibitorInfo?.['DIRECTORY']['FACEBOOK'];
          company_twitter = exhibitorInfo?.['DIRECTORY']['TWITTER'];
          company_snapchat = exhibitorInfo?.['DIRECTORY']['SNAPCHAT'];
          company_instagram = exhibitorInfo?.['DIRECTORY']['INSTAGRAM'];          
          company_linkdin = exhibitorInfo?.['DIRECTORY']['LINKEDIN'];
          company_tiktok = exhibitorInfo?.['DIRECTORY']['TIKTOK'];
          company_call_label = exhibitorInfo?.['DIRECTORY']['ACTION_BUTTON_LABEL'];
          company_call_link = exhibitorInfo?.['DIRECTORY']['ACTION_BUTTON_LINK'];
          company_website = exhibitorInfo?.['DIRECTORY']['WEBSITE'];
          toll_free = exhibitorInfo?.['DIRECTORY']['TOLL_FREE'];
          cell_phone = exhibitorInfo?.['DIRECTORY']['CELL_PHONE'];
          brand_name = exhibitorInfo?.['DIRECTORY']['BRAND_NAME'];
          company_name = exhibitorInfo?.['DIRECTORY']['COMPANY_NAME'];
          fax = exhibitorInfo?.['DIRECTORY']['FAX'];
          division = exhibitorInfo?.['DIRECTORY']['DIVISION'];
          c_title = exhibitorInfo?.['DIRECTORY']['TITLE'];
          credentials = exhibitorInfo?.['DIRECTORY']['CREDENTIALS'];
          company_headerimg_url = exhibitorInfo?.['DIRECTORY']['HEADERIMAGE'];
          company_bgimg_url = exhibitorInfo?.['DIRECTORY']['BACKGROUND'];
          company_tag_featured = exhibitorInfo?.['DIRECTORY']['FEATURED'];
          company_tag_member = exhibitorInfo?.['DIRECTORY']['MEMBER'];
          company_tag_3dbooth = (exhibitorInfo?.['DIRECTORY']['3DMODEL']&&exhibitorInfo?.['DIRECTORY']['3DMODEL']!="")?true:false;
          company_tag_sponser = exhibitorInfo?.['DIRECTORY']['SPONSOR'];
          company_tag_level = exhibitorInfo?.['DIRECTORY']['BOOTHLEVEL'];
          setBookmark(exhibitorInfo?.['BOOKMARKED'])

        }
        setLoaded(true)
        
      setTimeout(()=>{
        if(!props.ui.openCompanyProfile.parent && props.ui.openCompanyProfile.scrollTop && scrollRef){
          scrollRef.current.getElementsByClassName("MuiPaper-root")[0].scrollTop = props.ui.openCompanyProfile.scrollTop
        }
      }, 1000)

      }
    }
  }
  
  const handleOpenCompanyModal=()=>{
    var booth = props.ui.openCompanyProfile.booth;
    var row = {}
    row['booth'] = booth

    props.setEditCompany(row)
    props.setOpenCompanyModal(true)
  }

  

  const fetchBookmarkList = ()=>{
    {
      var token = false
      try{
        token =  getTokenPlanner();
        if(props.role=='admin'){
          token = getTokenCookie()
        }
      }
      catch(e){

      }
      if(token){
        var url = `${ESHOW_URL()}?method=getPlanner&token=${token}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getPlanner&token=${token}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            if(result.SUCCESS){
              props.setBookmarkList(result.BOOKMARKS)
            }
            else{

            }
        }).catch(error => {
        })  
      }
    }
  }
  
  const confirmComapnyModal=()=>{

  }
  const callBookmarkApi= async (exhibitor, value)=>{  
    const _token = getTokenPlanner();
    var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
    if(value){
      var booths = ''
      if(exhibitor.BOOTHS){
        booths = exhibitor.BOOTHS
      }
      else if(exhibitor.BOOTH_NO){
        booths = exhibitor.BOOTH_NO
      }
      BOOKMARKS = [
        ...BOOKMARKS,
        {
          COMPANY_NAME: exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:"",
          NOTES:"",
          STATUS:'',
          BOOTHS:booths,
          EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
        }
      ]
    }
    else{
      BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
    }
    
    var postData =
    {
      SUCCESS:true,
      BOOKMARKS:BOOKMARKS
    };

    var formdata = {
      query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }  
    var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
      const eshowResponse = res.data;
      if(eshowResponse.SUCCESS == true){
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to save bookmark");
      }
      props.setBookmarkList(BOOKMARKS)
    }).catch(error => {
        console.log("error", error);
    })
  }

  const doBookmark = (booth)=>{
    var exhibitor = false
    var exhibitors = booth?.exhibitors
    if(exhibitors && exhibitors.length > 0){
      exhibitor = {...exhibitors[0]}
      exhibitor.BOOTHS = booth.booth_number
    }

    if(exhibitor){
      const _token = getTokenPlanner();
      if((props.role !='admin') && (!_token || !_token)){
        props.setOpenLogin(true)
        return false;
      }
      setBookmark(!bookmark)
      callBookmarkApi(exhibitor, !bookmark)
      // props.setBookmardUpdated(true)
    }
  }

  function searchTree(element, KEY_ID){
    if(element.KEY_ID == KEY_ID){
         return element;
    }else if (element.SUBCAT != null){
         var i;
         var result = null;
         for(i=0; result == null && i < element.SUBCAT.length; i++){
              result = searchTree(element.SUBCAT[i], KEY_ID);
         }
         return result;
    }
    return null;
  }

  const onClickCategory = (KEY_ID)=>{
    var categoryData = {
        KEY_ID: "0",
        TITLE: "Parent",
        SUBCAT: []
      }
    if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
        // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
        categoryData.SUBCAT = (props.main.categoryOptions)
    }
    else{
    
    }
    var name  = ""
    var element = searchTree(categoryData, KEY_ID)
    props.setOpenCompanyProfile(false)
    props.setLeftPage('category'); props.setLeftSelectedCategory(element)
  }


  const getCategoryName = (KEY_ID)=>{
    var categoryData = {
        KEY_ID: "0",
        TITLE: "Parent",
        SUBCAT: []
      }
    if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
        // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
        categoryData.SUBCAT = (props.main.categoryOptions)
    }
    else{
    
    }
    var name  = ""
    var element = searchTree(categoryData, KEY_ID)
    if(element){
        name = element.TITLE
    }
    return name;
  }


  const renderCoCompany = (co_company, index)=>{
    var element = []
    element.push(
      <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box'}}>
        <Box style={{width:'80px', boxSizing:'border-box'}}>
          {co_company?.['LOGO'] &&
            <img style={{width:'80px', height:'80px', objectFit:'contain', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={assetUrl(co_company?.['LOGO'])}/>
          }
        </Box>
        <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px - 70px)', padding: '3px 12px', boxSizing:'border-box'}}>
          <div>
            <h4 style={{marginTop:0, marginBottom:6}}>
              <a className='sub-exhibtor-title' href="javascript:void(0)"
                onClick={()=>{
                  // console.log("scrollRef.current", scrollRef.current.getElementsByClassName("MuiPaper-root")[0].scrollTop)                  
                  resetForm()
                  props.setOpenCompanyProfile({booth:{exhibitors:[co_company]}, open:true, parent:props.ui.openCompanyProfile?.booth, scrollTop:(scrollRef?.current?(scrollRef.current.getElementsByClassName("MuiPaper-root")[0].scrollTop):0)})
                }}  
              >
                {co_company?.['COMPANY_NAME']}
              </a>
            </h4>
            <p style={{marginTop:0, marginBottom:6}}>Booth #: {co_company?.['BOOTH_NO']}</p>
            <p style={{marginTop:0, marginBottom:6}}>{co_company?.['TEXT_LINE']}</p>
            {props.role == "admin" &&
            <a className='sub-exhibtor-edit-btn' href="javascript:void(0)"
              onClick={()=>{
                props.setEditCompany({booth:{exhibitors:[co_company]}, sub:true})
                props.setOpenCompanyModal(true)
              }}  
            >
              Edit
            </a>
            }
          </div>
        </Box>
        <Box display={'flex'} alignItems={'center'} style={{width:'70px', padding: '3px 12px', boxSizing:'border-box'}} textAlign={'center'}>
          {props.role !== "admin" &&
            <IconButton edge="end" 
              onClick={()=>{
                var tmp_co_companies = [...co_companies]
                var newVal = !co_company.BOOKMARKED
                tmp_co_companies[index] = {...co_company, BOOKMARKED:newVal}
                setCo_companies(tmp_co_companies)
                const _token = getTokenPlanner();
                if((props.role !='admin') && (!_token || !_token)){
                  props.setOpenLogin(true)
                  return false;
                }
                callBookmarkApi(co_company, newVal)
              }}
            >
              {
                co_company.BOOKMARKED?
                <BookmarkIcon style={{color:'#e54839'}}/>:
                <BookmarkBorderOutlinedIcon/>
              }
            </IconButton>
          }
        </Box>
      </Box>
    )
    return element;
  }

  
  const formatDate = (dateStr)=>{
    
    const date = moment(dateStr);
    date.tz('America/Chicago');
    return date.format('MM/DD/YYYY hh/mm A Z (z)');
  }
  

  const sendCallAction = ()=>{
    var email = openCallAction.EMAIL_NOTIFICATION
    // email = 'azuresnow55@gmail.com'
    if(email){
      var body = '';
      for(var i = 0; i <openCallAction?.QUESTIONS?.length; i++){
        var question = openCallAction?.QUESTIONS[i]
        if(question.QUESTION_TYPE == 'textline' || question.QUESTION_TYPE == 'radio' || question.QUESTION_TYPE == 'pulldown'){
          // formdata[question.QUESTION_NAME] = question.value
          body+=`${question.QUESTION_NAME}: ${question.value}<br>`
        }
        if(question.QUESTION_TYPE == 'checkbox'){
          var value = question.value
          var tmp = ""
          if(value){
            for(var x in value){
              if(value[x]){
                if(tmp !=""){
                  tmp+=", "
                }
                tmp+=x
              }
            }
          }
          // formdata[question.QUESTION_NAME] = tmp
          
          body+=`${question.QUESTION_NAME}: ${tmp}<br>`
        }
      }

      var _token = getTokenPlanner();
      if(!_token){
        _token = getTokenCookie()
      }
      console.log("token", _token)
      // return;
      var postData =
      {
        MAIL_TO:email,
        SUBJECT:'Request for information',
        BODY:body
      };

      
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json'
        }
      }  
      var url = `${ESHOW_URL()}?method=putEmail&token=${_token}`
      axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          props.setOpenSnack(true);
          props.setSnackMsg("Submitted successfully");
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg("Something went wrong");
        }
      }).catch(error => {
          console.log("error", error);
      })      
    }
  }
  
  const renderCompanyHeader = (company_header)=>{
    if(!company_header) return (<></>)
    return(
      <Box mb={3}>
        <Carousel
          infinite={company_header.length>1} 
          showDots={company_header.length>1}
          arrows={company_header.length>1}
          responsive={responsive}
          dotListClass="custom-dot-list-style"
          className={company_header.length>1?'video-photo-hover':''}
        >
          {
            company_header.map((item, index)=>{
              if(item.MEDIA_TYPE == "Image"){
                return(
                  <div key={index} className='carousel-item'>
                    <img src={item.URL}/>
                  </div>
                )
              }
              else if(item.MEDIA_TYPE == "Video"){
                return(
                  <div key={index} className='carousel-item' style={{height:'auto'}}>
                    <ReactPlayer controls muted={true} playing={true} className="video" height="293px"  url={item.URL}/>
                  </div>
                )
              }
            })
          }
        </Carousel>
      </Box>
    )
  }

  const renderCoCompanies = (co_companies)=>{
        
    return(
        <div className=''>
          {
            co_companies?.map((co_company, index)=>{
              return(
                <div key={index}>
                  {renderCoCompany(co_company, index)}
                </div>
              )
            })
          }
        </div>
    )
  }
  
  var sales_bookmarks_show = true
  if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
    sales_bookmarks_show = false
  }
  var download_show = true
  var contact_show = true
  if(props.main.showSetup?.PUBLIC?.DOWNLOAD_LINKS == 'hide'){
    download_show = false
  }
  if(props.main.showSetup?.PUBLIC?.CONTACT_INFO == 'hide'){
    contact_show = false
  }

  return (
    <>
      {loaded &&
      <>
      {company_tag_featured &&
        <Box>
          {props.role=='admin'?
          <FeaturedLabel style={{backgroundColor:'#EE5D10'}}/>:
          <FeaturedLabel/>
          }
        </Box>
      }
      <div className="" style={{backgroundColor: company_headerimg_url?"unset":bgColor}}>          
        <Fade in={true} timeout={1200} style={{height:'100%'}}>
          <div>              
            <DrawerHeader style={{marginTop:0, display:'flex'}}>
            {/* && tpage && tpage != '' && */}
              {props.role == "admin" &&
              <>
                <IconButton onClick={()=>handleOpenCompanyModal()}>
                  <BorderColorOutlinedIcon/>
                </IconButton>
              </>
              }
              <IconButton onClick={()=>{
                if(props.ui.openCompanyProfile?.parent){
                  props.setOpenCompanyProfile({booth:props.ui.openCompanyProfile?.parent, open:true, scrollTop:props.ui.openCompanyProfile?.scrollTop, parent:false})
                }
                else{
                  resetForm()
                  props.setOpenCompanyProfile(false);
                }
              }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              <Box style={{textAlign:'right'}} pt={1}>
              </Box>
            </DrawerHeader>
            
            <Box display='flex' style={{alignItems:'center', justifyContent:'start', paddingLeft: 20, margin: 0, height:'100%'}}>
              <h3 style={{fontSize:20, margin: 0, textAlign:'left'}}>{companyTextFlag?"":companyName} {boothName}</h3>
              {sales_bookmarks_show && props.role !== "admin" &&
                <IconButton style={{marginLeft:10}} edge="end" onClick={()=>{ doBookmark(booth)}}>
                  {
                    bookmark?
                    <BookmarkIcon style={{color:'#e54839'}}/>:
                    <BookmarkBorderOutlinedIcon/>
                  }
                </IconButton>
              }
            </Box>
          </div>
        </Fade>
      </div>
      <Divider />
      <Fade in={true} timeout={1500}>
      <Box style={{backgroundImage:`url(${company_bgimg_url})`}} height='calc(100% - 64px)' pl={1} pr={1} pb={4} textAlign='left' className="backgroundBg">
        <Box  sx={{ display: { xs: 'none', md: 'block' } }}>
          {company_headerimg_url &&
            <img style={{marginLeft:-8, marginRight:-8, width:'calc(100% + 16px)'}} width="100%" height="auto" src={assetUrl(company_headerimg_url)}/>
          }
        </Box>
        {/* {company_bgimg_url &&
          <img  className="bgImg" width="100%" height="100%" src={company_bgimg_url}/>
        } */}
        <Box mt={3}>
          {/* <Box mb={2}><label><b>Category and tags</b></label></Box>           */}
          <Box>
            
            {company_tag_member &&
            <Chip className='category-button' size="small" style={{marginRight:7, marginBottom:7, backgroundColor:'rgb(213 5 48)'}} label='Member' color="success" />
            }
            {company_tag_sponser &&
            <Chip className='category-button' size="small" style={{marginRight:7, marginBottom:7, backgroundColor:'rgb(213 5 48)'}} label='Sponsor' color="success" />
            }
            {company_tag_level &&
            <Chip className='category-button' size="small" style={{marginRight:7, marginBottom:7, backgroundColor:'rgb(213 5 48)'}} label={company_tag_level} color="success" />
            }
            {company_tag_3dbooth &&
            <Chip className='category-button' size="small" style={{marginRight:7, marginBottom:7, backgroundColor:'rgb(213 5 48)'}} label='3D' color="success" />
            }
          </Box>
          <Box mt={2}>
            {company_category &&
              <>
                {
                  company_category.map((item, index)=>{
                    return (
                      <Chip className='category-button' size="small" key={index} style={{marginRight:7, marginBottom:7, cursor: 'handler'}} label={item.ANSWER_TITLE} onClick={()=>onClickCategory(item.ANSWER_KEY)} color="primary"/>
                    )
                  })
                }
              </>
            }
            {/* {company_main_category == 'A/V Equipment' &&
            <>
                {company_sub_category_displays &&
                <Chip style={{marginRight:10}} label='Displays' color="success" />
                }
                {company_sub_category_podiums &&
                <Chip style={{marginRight:10}} label='Podiums' color="success" />
                }
                {company_sub_category_laptops &&
                <Chip style={{marginRight:10}} label='Laptops' color="success" />
                }
            </>
            } */}
          </Box>
          
          
        </Box>
        <Box mt={3} mb={3} textAlign='left'>
          {/* <Box mb={2} textAlign='left'><label><b>Logo</b></label></Box>    */}
          <Box textAlign='center'>
            {renderCompanyHeader(company_header)}
            <img style={{ margin:'0 auto', maxWidth:'100%', height:'auto', maxHeight:'100px'}} width="auto" height="100px" src={company_log_url}/>
          </Box>
          <div style={{paddingTop:10}}>
            <ShowMore
                lines={5}
                more='Read More'
                less='Read Less'
                anchorClass=''
            >
              {company_description} 
            </ShowMore>
          </div>
        </Box>      
          
        {exhibitor?.['DIRECTORY']?.['SURVEYS']?.length > 0 &&  
          <Box mt={3} mb={3} textAlign='left'>
          {
            exhibitor?.['DIRECTORY']['SURVEYS'].map((item, index)=>{
              return(
                <div key={index}>
                  <h4 style={{marginBottom:4}}>{item.QUESTION_NAME}</h4>
                  {
                    item.RESPONSES?.length > 0 &&
                    <>
                    {
                      item.RESPONSES.map((response, index1)=>{
                        return(
                          <div key={index1}>
                            <p style={{marginTop:0, marginBottom:5}}>{response.ANSWER_TITLE}</p>
                          </div>
                        )
                      })
                    }
                    </>
                  }
                </div>
              )
          })
          }
        </Box>     
        }
        {co_companies?.length > 0 && !props.ui.openCompanyProfile?.parent &&
        <>
          <Divider></Divider>
          <Box mt={3}>
            <>              
              <h3 class="directory-header" style={{marginBottom:'10px'}}>Co-Exhibitors</h3>
              {
                renderCoCompanies(co_companies)
              }
            </>
          </Box>
        </>
        }
        {((company_photos && company_photos.length>0) || (company_videos && company_videos.length>0)) &&
        <Divider></Divider>
        }
        <Box style={{clear:'both'}} mt={3}>
          
          {((company_photos && company_photos.length>0) || (company_videos && company_videos.length>0)) &&
          <h3 class="directory-header" style={{marginBottom:'10px'}}>Videos & Photos</h3>
          }
          {company_photos && company_photos.length>0 &&
            <Carousel
              infinite={company_photos.length>1} 
              showDots={company_photos.length>1}
              arrows={company_photos.length>1}
              responsive={responsive}
              dotListClass="custom-dot-list-style"
              className={company_photos.length>1?'video-photo-hover':''}
            >
              {
                company_photos.map((photo, index)=>{
                  return(
                    <div key={index} className='carousel-item'>
                      <img src={photo}/>
                    </div>
                  )
                })
              }
            </Carousel>
          }
        </Box>
        {company_videos && company_videos.length>0 &&
        <Box mt={3}>
          <Box mt={3}>
              <Carousel
                infinite={company_videos.length>1} 
                showDots={company_videos.length>1}
                responsive={responsive}
                arrows={company_videos.length>1}
                dotListClass="custom-dot-list-style"
                className={company_videos.length>1?'video-photo-hover':''}
                autoPlay = {false}
              >
                {
                  company_videos.map((video, index)=>{
                    return(
                      <div key={index} className='carousel-item' style={{height:'auto'}}>
                        <ReactPlayer controls className="video" url={video.URL} />
                        <div style={{position:'absolute', bottom:'61px', left: '12px', color:'white'}}>{video.TITLE}</div>
                      </div>
                    )
                  })
                }
              </Carousel>
          </Box>
        </Box>
        }
        {exhibitor?.['DIRECTORY']?.['PRODUCTS']?.length > 0 &&
        <Box mt={3}>
          <Divider></Divider>
          <Box className='exhibitor-product-div' style={{clear:'both'}} mt={3}>
            <h3 class="directory-header" style={{marginBottom:'10px'}}>Products</h3>
            {exhibitor?.['DIRECTORY']['PRODUCTS'] && exhibitor?.['DIRECTORY']['PRODUCTS'].length>0 &&
              <Carousel
                infinite={false} 
                showDots={false}
                responsive={responsive1}
                dotListClass="custom-dot-list-style"
                className={exhibitor?.['DIRECTORY']['PRODUCTS'].length>1?'video-photo-hover':''}
              >
                {
                  exhibitor?.['DIRECTORY']['PRODUCTS'].map((product, index)=>{
                    return(
                      <div key={index} className='carousel-thumb' style={{position:'relative'}}>
                        {product.NEW_PRODUCT == 1&&
                        <img width="66px" style={{position:'absolute', left:'-1px'}} src="/asset/new_product.png"  onClick={()=>{setOpenProductDetails(product)}}/>
                        }
                        <img style={{width:'100%', height: '80px', objectFit:"cover"}} src={assetUrl(product.HEADER_IMAGE)}  onClick={()=>{setOpenProductDetails(product)}}/>
                        <Box p={1}>
                          <p className='product-title' onClick={()=>{setOpenProductDetails(product)}}>{product.TITLE}</p>
                          
                          {product.DOCUMENT && download_show &&
                          <>
                            {product.DOCUMENT_TYPE == 'video'?
                            <Box mt={1}>
                              <a className='' style={{marginRight:'10px', color:'#0069aa'}} target='_blank' download={true} href={product.DOCUMENT}>                              
                                Video
                              </a>
                            </Box>:
                            <Box mt={1}>
                              <a className='' style={{marginRight:'10px', color:'#0069aa'}} target='_blank' download={true} href={product.DOCUMENT}>                              
                                Download
                              </a>
                            </Box>
                            }
                          </>
                          }
                        </Box>
                      </div>
                    )
                  })
                }
              </Carousel>
            }
          </Box>
        </Box>
        }
        {exhibitor?.['DIRECTORY']?.['SHOW_SPECIALS']?.length > 0 &&
        <>
          <Divider></Divider>
          <Box mt={3}>
            <>
            
              <h3 class="directory-header" style={{marginBottom:'10px'}}>Specials</h3>
              <div className=''>
              {
                exhibitor?.['DIRECTORY']['SHOW_SPECIALS']?.map((special, index)=>{
                  return(
                    <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box'}}>
                      <Box style={{width:'80px', boxSizing:'border-box'}}>
                        {special?.['URL'] &&
                          <img style={{width:'80px', height:'80px', objectFit:'cover', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={special?.['URL']}/>
                        }
                      </Box>
                      <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px - 70px)', padding: '3px 12px', boxSizing:'border-box'}}>
                        <div>
                          <h4 style={{marginTop:0, marginBottom:6}}>
                            <a className='sub-exhibtor-title' href="javascript:void(0)"
                              onClick={()=>{
                                setOpenSpecialDetails(special)
                              }}  
                            >
                              {special?.['TITLE']}
                            </a>
                          </h4>
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}>{special?.['SUB_TITLE']}</p>
                          <p className='category-title' style={{marginTop:0, marginBottom:6}}>{special?.['CATEGORY_TITLE']}</p>
                        </div>
                      </Box>
                      { download_show &&
                      <Box display={'flex'} alignItems={'center'} style={{width:'70px', padding: '3px 12px', boxSizing:'border-box'}} textAlign={'center'}>
                        <IconButton edge="end" target='_blank' download href={special.URL}>
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </IconButton>
                      </Box>
                      }
                    </Box>
                  )
                })
              }
            </div>
            </>
          </Box>
        </>
        }

        {exhibitor?.['DIRECTORY']?.['DOCUMENTS']?.length > 0 &&
        <>
          <Divider></Divider>
          <Box mt={3}>
            <>
            
              <h3 class="directory-header" style={{marginBottom:'10px'}}>Documents</h3>
              <div className=''>
              {
                exhibitor?.['DIRECTORY']['DOCUMENTS']?.map((document, index)=>{
                  return(
                    <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box'}} className='exhibitor-document'>
                      <Box style={{width:'80px', boxSizing:'border-box'}}>
                        {document?.['HEADER_IMAGE'] &&
                          <img style={{width:'80px', height:'80px', objectFit:'cover', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={document?.['HEADER_IMAGE']}/>
                        }
                      </Box>
                      <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px - 70px)', padding: '3px 12px', boxSizing:'border-box'}}>
                        <div>
                          <h4 style={{marginTop:0, marginBottom:6}}>
                            <a className='sub-exhibtor-title' href="javascript:void(0)"
                              onClick={()=>{
                              }}  
                            >
                              {document?.['TITLE']}
                            </a>
                          </h4>
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}>{document?.['SUB_TITLE']}</p>
                          <p className='category-title' style={{marginTop:0, marginBottom:6}}>{document?.['CATEGORY_TITLE']}</p>
                          <ShowMore
                              lines={5}
                              more='Read More'
                              less='Read Less'
                              anchorClass=''
                            >
                              {document?.['DESCRIPTION']}
                          </ShowMore>
                        </div>
                      </Box>
                      { download_show &&
                      <Box display={'flex'} alignItems={'center'} style={{width:'70px', padding: '3px 12px', boxSizing:'border-box'}} textAlign={'center'}>
                        <IconButton edge="end" target='_blank' download href={document.URL}>
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </IconButton>
                      </Box>
                      }
                    </Box>
                  )
                })
              }
            </div>
            </>
          </Box>
        </>
        }


        
        {exhibitor?.['DIRECTORY']?.['EVENTS']?.length > 0 &&
        <>
          <Divider></Divider>
          <Box mt={3}>
            <>
            
              <h3 class="directory-header" style={{marginBottom:'10px'}}>Exhibtor Events</h3>
              <div className=''>
              {
                exhibitor?.['DIRECTORY']['EVENTS']?.map((release, index)=>{
                  return(
                    <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box'}}>
                      <Box style={{width:'80px', boxSizing:'border-box'}}>
                        {release?.['speakers']?.[0]?.['speaker_photo'] &&
                          <img style={{width:'80px', height:'80px', objectFit:'cover', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={release?.['speakers']?.[0]?.['speaker_photo']}/>
                        }
                      </Box>
                      <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px)', padding: '3px 12px', boxSizing:'border-box'}}>
                        <div>
                          <h4 style={{marginTop:0, marginBottom:6}}>
                            <a className='sub-exhibtor-title' href="javascript:void(0)"
                              onClick={()=>{
                                setOpenEventsDetails(release)
                              }}  
                            >
                              {release?.['title']}
                            </a>
                          </h4>
                          <div
                            className='sub-title' style={{marginTop:0, marginBottom:6}}
                          >
                            <ShowMore
                              lines={5}
                              more='Read More'
                              less='Read Less'
                              anchorClass=''
                            >
                              {release?.['description']} 
                            </ShowMore>
                          </div>
                          {release?.['classes']?.[0]?.['venue_title'] &&
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}><strong>Venue: {release?.['classes']?.[0]?.['venue_title']}: {release?.['classes']?.[0]?.['room_title']}</strong></p>
                          }
                          {release?.['classes']?.[0]?.['class_end'] &&
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}><strong>Event Date: {formatDate(release?.['classes']?.[0]?.['class_end'])}</strong></p>
                          }
                          {release?.['speakers']?.[0]?.['first_name'] &&
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}><strong>Speaker: {release?.['speakers']?.[0]?.['first_name']} {release?.['speakers']?.[0]?.['last_name']}</strong></p>
                          }
                          {release.DOCUMENT &&  download_show &&
                          <Box mt={1}>
                            <a className='' style={{marginRight:'10px', color:'#0069aa'}} target='_blank' download={true} href={release.DOCUMENT}>                              
                              Download
                            </a>
                          </Box>
                          }
                        </div>
                      </Box>
                      
                    </Box>
                  )
                })
              }
            </div>
            </>
          </Box>
        </>
        }

        {exhibitor?.['DIRECTORY']?.['PRESS_RELEASES']?.length > 0 &&
        <>
          <Divider></Divider>
          <Box mt={3}>
            <>
            
              <h3 class="directory-header" style={{marginBottom:'10px'}}>Press Releases</h3>
              <div className=''>
              {
                exhibitor?.['DIRECTORY']['PRESS_RELEASES']?.map((release, index)=>{
                  return(
                    <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box'}}>
                      <Box style={{width:'80px', boxSizing:'border-box'}}>
                        {release?.['HEADER_IMAGE'] &&
                          <img style={{width:'80px', height:'80px', objectFit:'cover', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={release?.['HEADER_IMAGE']}/>
                        }
                      </Box>
                      <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px)', padding: '3px 12px', boxSizing:'border-box'}}>
                        <div>
                          <h4 style={{marginTop:0, marginBottom:6}}>
                            <a className='sub-exhibtor-title' href="javascript:void(0)"
                              onClick={()=>{
                                setOpenReleaseDetails(release)
                              }}  
                            >
                              {release?.['TITLE']}
                            </a>
                          </h4>
                          <div
                            className='sub-title' style={{marginTop:0, marginBottom:6}}
                          >
                            <ShowMore
                              lines={5}
                              more='Read More'
                              less='Read Less'
                              anchorClass=''
                            >
                              {release?.['DESCRIPTION']} 
                            </ShowMore>
                          </div>
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}>{release?.['CITY']}, {release?.['STATE']} {release?.['COUNTRY']}  {release?.['PHONE']}</p>
                          {release.DOCUMENT &&  download_show &&
                          <Box mt={1}>
                            <a className='' style={{marginRight:'10px', color:'#0069aa'}} target='_blank' download={true} href={release.DOCUMENT}>                              
                              Download
                            </a>
                          </Box>
                          }
                        </div>
                      </Box>
                      
                    </Box>
                  )
                })
              }
            </div>
            </>
          </Box>
        </>
        }
        
        <Box mt={2}>
          {(company_contact_fn||company_contact_ln||company_contact_email||company_contact_phone) &&
          <Divider></Divider>
          }
          {/* <Box mt={3}><label><b>Contact info</b></label></Box>   */}
          <Box mt={2}>
            {company_contact_fn} {company_contact_ln} {credentials}{company_contact_fn||company_contact_ln?<br/>:''} 
            {
              ((c_title && c_title !='') ) &&
              <>{c_title} <br/></>
            }
            {
              company_contact_phone && company_contact_phone !='' &&
              <>Work Phone: {company_contact_phone} {company_contact_phone_ext?'Ext:':''} {company_contact_phone_ext}{company_contact_phone||company_contact_phone_ext?<br/>:''}</>
            }
            {
              cell_phone && cell_phone !='' &&
              <>Cell Phone: {cell_phone}<br/></>
            }
            <br/>
            {
              company_name && company_name !='' &&
              <>{company_name}<br/></>
            }
            {
              brand_name && brand_name !='' &&
              <>{brand_name}<br/></>
            }
            {
              division && division !='' &&
              <>{division}<br/></>
            }
            {company_contact_street}{company_contact_street?<br/>:''}
            {company_contact_address2}{company_contact_address2?<br/>:''}
            {company_contact_city}{(company_contact_city&&(company_contact_state||company_contact_zip||company_contact_country)) &&<>,</>} {company_contact_state} {company_contact_zip}<br/>
            {company_contact_country}{company_contact_country?<br/>:''}
            <br/>
            {
              toll_free && toll_free !='' &&
              <>Toll Free: {toll_free}<br/></>
            }
            {
              fax && fax !='' &&
              <>Fax: {fax}<br/></>
            }
          </Box>
        </Box>
        {contact_show&&
        <Box mt={2}>
          {(company_facebook||company_twitter||company_instagram||company_linkdin||company_tiktok) &&
          <Divider></Divider>
          }
          {/* <Box mt={3}><label><b>Soicial Links</b></label></Box>   */}
          <Box display={'flex'} justifyContent={'center'} mt={2} textAlign='center'>
            {company_facebook&&
            <Tooltip title="Facebook">
              <a className="social-icon" target="_blank" href={`https://www.facebook.com/${company_facebook}`}>
                <i class="fa-brands fa-facebook-f"></i>
              </a>
            </Tooltip>
            }
            {company_twitter&&
            <Tooltip title="Twitter">
              <a className="social-icon" target="_blank" href={`https://twitter.com/${company_twitter}`}>
                <i class="fa-brands fa-x-twitter"></i>
              </a>
            </Tooltip>
            }
            {company_instagram&&
            <Tooltip title="Instagram">
              <a className="social-icon" target="_blank" href={`https://www.instagram.com/${company_instagram}`}>
                <i class="fa-brands fa-instagram"></i>
              </a>
            </Tooltip>
            }
            {company_linkdin&&
            <Tooltip title="Linkedin">
              <a className="social-icon" target="_blank" href={`https://www.linkedin.com/company/${company_linkdin}`}>
                <i class="fa-brands fa-linkedin-in"></i>
              </a>
            </Tooltip>
            }
            {company_tiktok&&
            <Tooltip title="Tiktok">
              <a className="social-icon" target="_blank" href={`https://www.tiktok.com/@${company_tiktok}`}>
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </Tooltip>
            }
            
          </Box>
        </Box>
        }
        <Box mt={2} mb={3} style={{paddingBottom: 50}}>
          {(exhibitor?.['DIRECTORY']?.['WEBSITE']||(exhibitor?.['DIRECTORY']?.['FORM']&& company_call_label)) &&
          <Divider></Divider>
          }
          <Box mt={2}>
            <Box mt={1} mb={3} textAlign='center'>
              
            {exhibitor?.['DIRECTORY']?.['WEBSITE'] &&
            <>
              <Button className='site-button' style={{marginRight:'10px'}} variant="contained" href={`https://${exhibitor?.['DIRECTORY']?.['WEBSITE'].trim().replace("https://", "").replace("http://", "")}`} target='_blank'>
                Website
              </Button>
            </>
            }        
            {exhibitor?.['DIRECTORY']?.['FORM']&& company_call_label&& 
            <>     
              {exhibitor?.['DIRECTORY']?.['FORM']?
                <Button variant="contained" onClick={()=>{setOpenCallAction(exhibitor?.['DIRECTORY']?.['FORM'])}}>
                  {company_call_label?.trim()}
                </Button>:
                <Button variant="contained" target="_blank" href={company_call_link?company_call_link:'#'}>
                  {company_call_label?.trim()}
                </Button>
              }
            </>
            }
            </Box>
          </Box>
        </Box>
      </Box>
      </Fade>
      </>
      }
      {
        openProductDetails &&         
        <Box className='exhibitor-details-popup'>
          
          {openProductDetails.NEW_PRODUCT == 1&&
            <img width="100px" style={{position:'absolute', left:'-1px', width:100, height:'auto'}} src="/asset/new_product.png"/>
          }
          <img  src={openProductDetails.HEADER_IMAGE}/>
          <div>
            <IconButton className='exhibitor-details-close-btn' size="small" aria-label="close" color="inherit" onClick={()=>setOpenProductDetails(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Box className='exhibitor-details-description' textAlign={'left'} p={2}>
            <h3 style={{marginTop:0}}>{openProductDetails.TITLE}</h3>
            <div className='sub-title'>{openProductDetails.SUB_TITLE}</div>
            <div className='category-title'>{openProductDetails.CATEGORY_TITLE}</div>
            <Box mt={2} display={'flex'}>
              {openProductDetails.DOCUMENT && download_show&&
              <>
                {openProductDetails.DOCUMENT_TYPE == 'video'?              
                <a className='round-icon-btn' style={{marginRight:'10px'}} target='_blank' href={openProductDetails.DOCUMENT}>
                  <VideocamIcon style={{color:'white'}}/>
                </a>:
                <a className='round-icon-btn' style={{marginRight:'10px'}} target='_blank' download href={openProductDetails.DOCUMENT}>
                  <DownloadIcon style={{color:'white'}}/>
                </a>
                }
              </>
              }
            </Box>
            <Box mt={2}>
              <ShowMore
                  lines={5}
                  more='Read More'
                  less='Read Less'
                  anchorClass=''
              >
                {openProductDetails.DESCRIPTION} 
              </ShowMore>
            </Box>
          </Box>
        </Box>
      }
      {
        openSpecialDetails &&         
        <Box className='exhibitor-details-popup'>
          {openSpecialDetails?.['URL'] &&
          <img  src={openSpecialDetails.URL}/>
          }
          <div>
            <IconButton className='exhibitor-details-close-btn' size="small" aria-label="close" color="inherit" onClick={()=>setOpenSpecialDetails(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Box className='exhibitor-details-description' textAlign={'left'} p={2}>
            <h3 style={{marginTop:0}}>{openSpecialDetails.TITLE}</h3>
            <div className='sub-title'>{openSpecialDetails.SUB_TITLE}</div>
            <div className='category-title'>{openSpecialDetails.CATEGORY_TITLE}</div>
            { download_show &&
            <Box mt={2} display={'flex'}>
              <a className='round-icon-btn' style={{marginRight:'10px'}} target='_blank' download href={openSpecialDetails.URL}>
                <i class="fa fa-download" aria-hidden="true"></i>
              </a>
            </Box>
            }       
            <Box mt={2}>
              <div> {openSpecialDetails.DESCRIPTION} </div>
            </Box>
          </Box>
        </Box>
      }
      {
        openReleaseDetails &&         
        <Box className='exhibitor-details-popup'>
          {openReleaseDetails?.['HEADER_IMAGE'] &&
          <img  src={openReleaseDetails.HEADER_IMAGE}/>
          }
          <div>
            <IconButton className='exhibitor-details-close-btn' size="small" aria-label="close" color="inherit" onClick={()=>setOpenReleaseDetails(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Box className='exhibitor-details-description' textAlign={'left'} p={2}>
            <h3 style={{marginTop:0}}>{openReleaseDetails.TITLE}</h3>
            <div className='sub-title'>{openReleaseDetails?.['CITY']}, {openReleaseDetails?.['STATE']} {openReleaseDetails?.['COUNTRY']}  {openReleaseDetails?.['PHONE']}</div>
            {openReleaseDetails.DOCUMENT && download_show &&
            <Box mt={2} display={'flex'}>
              <a className='round-icon-btn' style={{marginRight:'10px'}} target='_blank' download href={openReleaseDetails.URL}>
                <DownloadIcon style={{color:'white'}}/>
              </a>
            </Box>
            }
            <Box mt={2}>
              <ShowMore
                  lines={5}
                  more='Read More'
                  less='Read Less'
                  anchorClass=''
              >
                {openReleaseDetails.DESCRIPTION} 
              </ShowMore>
            </Box>
          </Box>
        </Box>
      }
      
      {
        openEventsDetails &&         
        <Box className='exhibitor-details-popup'>
          {openEventsDetails?.['HEADER_IMAGE'] &&
          <img  src={openEventsDetails.HEADER_IMAGE}/>
          }
          <div>
            <IconButton className='exhibitor-details-close-btn' size="small" aria-label="close" color="inherit" onClick={()=>setOpenEventsDetails(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Box className='exhibitor-details-description' textAlign={'left'} p={2}>
            <h3 style={{marginTop:0}}>{openEventsDetails.title}</h3>
            {openEventsDetails?.['classes']?.[0]?.['venue_title'] &&
            <p className='sub-title' style={{marginTop:0, marginBottom:6}}><strong>Venue: {openEventsDetails?.['classes']?.[0]?.['venue_title']}: {openEventsDetails?.['classes']?.[0]?.['room_title']}</strong></p>
            }
            {openEventsDetails?.['classes']?.[0]?.['class_end'] &&
            <p className='sub-title' style={{marginTop:0, marginBottom:6}}><strong>Event Date: {openEventsDetails?.['classes']?.[0]?.['class_end']}</strong></p>
            }
            {openEventsDetails?.['speakers']?.[0]?.['first_name'] &&
            <p className='sub-title' style={{marginTop:0, marginBottom:6}}><strong>Speaker: {openEventsDetails?.['speakers']?.[0]?.['first_name']} {openEventsDetails?.['speakers']?.[0]?.['last_name']}</strong></p>
            }
            {openEventsDetails.DOCUMENT && download_show && 
            <Box mt={2} display={'flex'}>
              <a className='round-icon-btn' style={{marginRight:'10px'}} target='_blank' download href={openEventsDetails.URL}>
                <DownloadIcon style={{color:'white'}}/>
              </a>
            </Box>
            }
            <Box mt={2}>
              <ShowMore
                  lines={5}
                  more='Read More'
                  less='Read Less'
                  anchorClass=''
              >
                {openEventsDetails.description} 
              </ShowMore>
            </Box>
          </Box>
        </Box>
      }
       {
        openCallAction &&         
        <Box className='exhibitor-details-popup'>
          {openCallAction?.['HEADER_IMAGE'] &&
          <img  src={openCallAction.HEADER_IMAGE}/>
          }
          <div>
            <IconButton className='exhibitor-details-close-btn' size="small" aria-label="close" color="inherit" onClick={()=>setOpenCallAction(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Box className='' textAlign={'left'} p={2}>
            <h3 style={{marginTop:0}}>{openCallAction.TITLE}</h3>
            <div className='sub-title'>{openCallAction.SUB_TITLE}</div>
            <Box mt={2}>
              <div> {openCallAction.DESCRIPTION} </div>
            </Box>
            <Box>
              {
                openCallAction?.QUESTIONS?.map((question, questionInd)=>{
                  return (
                    <Box>
                      {
                        question.QUESTION_TYPE == 'textline' &&
                        <FormControl sx={{ mt: 3, width:'100%' }} variant="standard">
                          {/* <FormLabel component="legend">{question.QUESTION_NAME}</FormLabel> */}
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            name={question.QUESTION_NAME}
                            margin="dense"
                            label={question.QUESTION_NAME}
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={question.value?question.value:""}
                            onChange={(e)=>{
                              var questions = [...openCallAction?.QUESTIONS];
                              questions[questionInd] = {...question,  value: e.target.value}
                              setOpenCallAction({...openCallAction, QUESTIONS:questions})
                            }}
                            size="small"
                          />
                        </FormControl>
                      }
                      {
                        question.QUESTION_TYPE == 'checkbox' && 
                        <FormControl sx={{ mt: 3 }} variant="standard">
                          <FormLabel component="legend">{question.QUESTION_NAME}</FormLabel>
                          <FormGroup>
                            {
                              question.ANSWERS?.map((answer, index)=>{
                                return(
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox 
                                        checked={question[answer.ANSWER_TITLE]} 
                                        onChange={(e)=>{
                                          var questions = [...openCallAction?.QUESTIONS];
                                          var value = {}
                                          if(question.value){
                                            value  = {...question.value}
                                          }
                                          value = {...value,  [answer.ANSWER_TITLE]: !value[answer.ANSWER_TITLE]}
                                          questions[questionInd] = {...question,  value}
                                          setOpenCallAction({...openCallAction, QUESTIONS:questions})
                                        }}
                                        name={answer.KEY_ID} />
                                    }
                                    label={answer.ANSWER_TITLE}
                                  />
                                )
                              })

                            } 
                          </FormGroup>
                        </FormControl>
                      }
                      {
                        question.QUESTION_TYPE == 'radio' && 
                        <FormControl sx={{ mt: 3 }}>
                          <FormLabel id="radio-buttons-group-label">{question.QUESTION_NAME}</FormLabel>
                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={question.value}
                            onChange={(e)=>{
                              var questions = [...openCallAction?.QUESTIONS];
                              questions[questionInd] = {...question,  value: e.target.value}
                              setOpenCallAction({...openCallAction, QUESTIONS:questions})
                            }}
                          >
                            {
                              question.ANSWERS?.map((answer, index)=>{
                                return(
                                  <FormControlLabel key={index} value={answer.ANSWER_TITLE} control={<Radio />} label={`${answer.ANSWER_TITLE}`} />
                                )
                              })

                            } 
                          </RadioGroup>
                        </FormControl>
                      }
                      {
                        question.QUESTION_TYPE == 'pulldown' && 
                        <FormControl sx={{ mt: 3 }} fullWidth size="small">
                          <FormLabel id="radio-buttons-group-label">{question.QUESTION_NAME}</FormLabel>
                          <Select
                            value={question.value}
                            onChange={(e)=>{
                              var questions = [...openCallAction?.QUESTIONS];
                              questions[questionInd] = {...question,  value: e.target.value}
                              setOpenCallAction({...openCallAction, QUESTIONS:questions})
                            }}
                            style={{width:'100%'}}
                          >
                            {
                              question.ANSWERS?.map((answer, index)=>{
                                return(
                                  <MenuItem key={index} value={answer.ANSWER_TITLE}>{`${answer.ANSWER_TITLE}`}</MenuItem>
                                )
                              })

                            } 
                          </Select>
                        </FormControl>
                      }
                    </Box>
                  )
                })
              }
              <Box mt={3} textAlign={'center'}>
                <Button variant="outlined" onClick={()=>{sendCallAction()}}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};
const mapDispatchProps = (dispatch) => {
  return {    
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
    setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
    setBookmarkList:  data=>dispatch(setBookmarkList(data)),
    setLeftSelectedCategory:  data=>dispatch(setLeftSelectedCategory(data)),
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
    setEditCompany: value=>dispatch(setEditCompany(value)),
    setSnackMsg: data=>dispatch(setSnackMsg(data)),
    setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
