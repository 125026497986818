import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { countries, API_URL, ESHOW_URL } from '../../config.js'
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import BusinessIcon from '@mui/icons-material/Business';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import { pink } from '@mui/material/colors';
import qs, { parse } from 'qs'
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import  area  from 'area-polygon'
import { setObjectBG, px2unit, unit2Px, addText, checkCombinable, checkContainSold } from '../../utils/CanvasTool'
import { getDefaultGroup } from '../../utils/Common'
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import { uuid4 } from './sketch_right/utils';
import { getTokenCookie } from '../../utils/Common'
import { fabric } from "fabric";
import {canvasApp, helperCanvas} from '../../utils/CanvasApp'
import {PaperComponent} from '../../utils/Common'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import Paper from '@mui/material/Paper';
const emails = ['username@gmail.com', 'user02@gmail.com'];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
var timeout = false;
var g_searchVal = ""
function SubExhibitorModal(props) {
  const { onClose, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  
  const [snackMsg, setSnackMsg] = React.useState('');
  const [openSnack, setOpenSnack] = React.useState(false);
  const [sales_form, setSalesForm] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [autoCombine, setAutoCombine] = React.useState(false)
  const [company, setCompany] = React.useState(null);
  const [contact, setContact] = React.useState(null);
  const [orgCompany, setOrgCompany] = React.useState(null);
  const [orgContact, setOrgContact] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);  
  const [allContacts, setAllContacts] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [saleforms, setSaleforms] = React.useState([]);
  const [saleitems, setSaleitems] = React.useState([]);
  const [boothSaleItems, setBoothSaleItems] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState("");
  const [searchContactVal, setSearchContactVal] = React.useState("");
  const [boothObjects, setBoothObjects] = React.useState([]);
  const [searchedList, serSearchedList] = React.useState([]);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [coBooths, setCoBooths] = React.useState([]);
  const [selectedCoBooths, setSelectedCoBooths] = React.useState([]);
  
  const filter = createFilterOptions();
  let query = useQuery();

  // const companies = [
  //   { label: 'The Shawshank Redemption', year: 1994 },
  //   { label: 'The Godfather', year: 1972 },
  // ];
  const handleChangeSalesForm = (event) => {
    setSalesForm(event.target.value);
  };

  useEffect(async() => {
    // if((props.boothTexts&& props.boothTexts.length > 1)){
    //   setStep(1)
    // }
    // else{
    //   setStep(2)
    // }
    setStep(2)
    setAutoCombine(false)
    setSearchVal("")
    setSearchContactVal("")
    serSearchedList([])
    if(props.coBooths){
      setCoBooths(props.coBooths)
    }
    else{
      if(props.boothObjects.length){
        var exhibitors = props.boothObjects[0].exhibitors
        var company_KEY_ID
        if(exhibitors && exhibitors.length > 0){
          company_KEY_ID = exhibitors[0].COMPANY_KEY
        }
        getExhibitor(company_KEY_ID)
      }
    }
  }, [open])

  
  useEffect(() => {
    g_searchVal = searchVal
  }, [searchVal])


  const getExhibitor = async (KEY_ID)=>{
    var result = null
    
    var booth = props.boothObjects[0]
    var url = `${ESHOW_URL()}?method=getExhibitor&COMPANY_KEY=${KEY_ID}&token=${getTokenCookie()}&booth_key=${booth?.KEY_ID}`
    const response = await axiosInstance.get(url);
    if(response.status == 200){
      result = response.data?.EXHIBITOR;
      if(result.BOOTHS){
        setCoBooths(result.BOOTHS)
      }
    }
    return result

 }

  const groupItemsPreview = (newBoothNumber, fromUndo, org_id)=>{
    if(!autoCombine && document.getElementById("previewSvg")) {
      document.getElementById("previewSvg").innerHTML = "";
      return;
    }
    var convertRateX= 1
    var convertRateY= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var unitRate = 12; //1feet = 12inch
    var boxUnitSize = 203.05;
    

    if(convertRateX){
        boxUnitSize = (10*unitRate/convertRateX)
    }
    var feetUnitSize = 20.305;       

    if(convertRateX){
      feetUnitSize = (unitRate/convertRateX)
    }

    if (mainCanvas && !mainCanvas.getActiveObject()) {
        return;
    }
    if (mainCanvas.getActiveObject().type !== 'activeSelection') {
        return;
    }
    var newBooths = [];
    var groupobject = mainCanvas.getActiveObject();
    var apartFlag = false;//checkifApart(groupobject);
    var objects = groupobject.getObjects();
    
    if(!checkCombinable(objects)){
        setSnackMsg("Failed to combine");
        setOpenSnack(true);
        return 
    }
    
    
    {        
        canvasApp.helperCanvas = mainCanvas
        var outpPutFontSize = 20
        var group = canvasApp.combineHelper.openCombineWindow(true,props.grouppingBooth);
        if(group){
          var rateX = 200/(group.width);
          var rateY = 150/(group.height);
  
          var fontSize = outpPutFontSize/Math.min(rateX, rateY)
          var textObjects = group.getObjects('text')
          for(var j = 0; j<  textObjects.length; j++){
              textObjects[j].set({fontSize:50/Math.max(convertRateX, 1)})
          }
          mainCanvas.renderAll()
          var decimal = 0;
          if((group.width)/feetUnitSize < 1) decimal = 2
        
          
          var f_text1 = new fabric.Text(px2unit(group.width, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
            left: 0,
            top: 0,
            fontSize:fontSize,
            class:'size'
          });   
          f_text1.left =  0 - f_text1.width/2
          f_text1.top =  0 - group.height/2 - f_text1.height*1.2
          group.add(f_text1);
          var f_text2 = new fabric.Text(px2unit(group.height, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
              left: 0,
              top: 0,
              fontSize:fontSize,
              class:'size'
          });    
          f_text2.left =  0+group.width/2+f_text2.height*0.2
          f_text2.top =   0 - f_text1.height/2
          group.add(f_text2);
          group.clipPath = null

          var drawermain = SVG();
          

          group.scaleX = Math.min(rateX, rateY)
          group.scaleY = Math.min(rateX, rateY)
          group.left = 552/2 - (group.width)*group.scaleX/2

          group.top = (f_text1.height)*group.scaleX;

          drawermain.svg(group.toSVG())
          console.log("group.toSVG()", group.toSVG())

          setTimeout(function(){
            if(document.getElementById("previewSvg")){
              document.getElementById("previewSvg").innerHTML = "";
            }
            drawermain.remove().addTo('#previewSvg').size('100%', '100%')
          },100)
        }
        if(group == false){
          apartFlag = true;
        }
    }
    if(checkContainSold(objects) && apartFlag){
        setSnackMsg("Failed to combine");
        setOpenSnack(true);
        return 
    }
    if(apartFlag){
        var orgObject;
        var objectsInGroup = groupobject.getObjects();
        var booth_numbers = [];
        for(var i = 0; i < objects.length; i++){
          booth_numbers.push(objects[i].booth_number);
        }
        booth_numbers.sort(function(a, b) {
          if (a < b) {
              return -1;
          }
          if (a > b) {
              return 1;
          }
          
          // names must be equal
          return 0;
        })
        newBoothNumber = "";
        if(booth_numbers[0]){
          newBoothNumber = booth_numbers[0]
        }
        objectsInGroup.forEach(function(object) {
            if(object.booth_number == newBoothNumber){
                orgObject = object; return;
            }
        });


        var boothId = "G_"+parseInt(Math.random()*(10000))

        var bgColor = "#dadada"
        var element = new fabric.Polygon(
          [
            {x: 0, y:0},
            {x: groupobject.width, y:0},
            {x: groupobject.width, y:groupobject.height},
            {x: 0, y:groupobject.height},
            {x: 0, y:0}
          ]
          , {
            class:"outline",
            left: groupobject.left,
            top: groupobject.top,
            fill: bgColor, 
            stroke: 'black',
            strokeWidth: 3/Math.max(convertRateX, 1),
            borderColor: 'black',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
        });
        
        element.set({
            borderColor: '#ff7777',
            cornerColor: 'red',
            cornerSize: 6,
            transparentCorners: false
        });

        var group = new fabric.Group([element]).set({
            width: groupobject.width,
            height: groupobject.height,
            id: org_id?org_id:'booth-'+boothId,
            class: 'booth',
            boothGroup: getDefaultGroup(props.main.groupOptions),
            class1 : 'booth-group',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
        });	
        group.set({
            borderColor: '#ff7777',
            cornerColor: 'red',
            cornerSize: 6,
            transparentCorners: false,
            layer:'booth',
            booth_number:props.grouppingBooth?props.grouppingBooth:newBoothNumber
        });        
        var newText = (orgObject.company?(orgObject.company+"\n"):"")+(props.grouppingBooth?props.grouppingBooth:newBoothNumber)
        addText(mainCanvas, group, newText)
        if(group){
          var rateX = 200/(group.width);
          var rateY = 150/(group.height);
  
          var fontSize = outpPutFontSize/Math.min(rateX, rateY)
          var textObjects = group.getObjects('text')
          for(var j = 0; j<  textObjects.length; j++){
              textObjects[j].set({fontSize:50/Math.max(convertRateX, 1)})
          }
          mainCanvas.renderAll()
          var decimal = 0;
          if((group.width)/feetUnitSize < 1) decimal = 2
        

          var f_text1 = new fabric.Text(px2unit(group.width, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
            left: 0,
            top: 0,
            fontSize:fontSize,
            class:'size'
          });   
          f_text1.left =  0 - f_text1.width/2
          f_text1.top =  0 - group.height/2 - f_text1.height*1.2
          group.add(f_text1);
          var f_text2 = new fabric.Text(px2unit(group.height, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
              left: 0+group.width/2,
              top: 0,
              fontSize:fontSize,
              class:'size'
          });    
          f_text2.top =   0 - f_text1.height/2
          group.add(f_text2);
          group.clipPath = null

          var drawermain = SVG();
          

          group.scaleX = Math.min(rateX, rateY)
          group.scaleY = Math.min(rateX, rateY)
          group.left = 300/2 - (group.width)*group.scaleX/2

          group.top = (f_text1.height)*group.scaleX;

          drawermain.svg(group.toSVG())
          console.log()

          setTimeout(function(){
            document.getElementById("previewSvg").innerHTML = "";
            drawermain.remove().addTo('#previewSvg').size('100%', '100%')
            
          },100)
        }
    }
    
  }
  
  const updateBoothSaleItems = (booths, boothObjects)=>{
    setBoothObjects(boothObjects)
    var newBoothSaleItems = [];
    if(booths){
      for(var i = 0; i< booths.length; i++){
        var options = [];
        var pricingOptions = props.main.pricingOptions
        var premiumOptions = props.main.premiumOptions
        var discountOptions = props.main.discountOptions
        var total = 0;
        var booth = booths[i]
        var boothObject = boothObjects[i]
        if(booth){
          var priceCheck = boothObject.priceCheck
          for(var k = 0; k <priceCheck?.length; k++){
            var row = priceCheck[k]              
            options.push(
              {
                saleitem_name:row.PRICE_LABEL?.trim(), 
                price_type:row.PRICE_TYPE, 
                saleitem_id:row.PRICE_KEY,
                saleitem_product_id:row.PRODUCT_KEY,
                saleitem_price:row.PRICE, 
                saleitem_qty:1, 
                saleitem_total:(row.PRICE*(row?.PRICE_TYPE?.trim() == 'square ft' || row?.PRICE_TYPE?.trim() == 'sq_ft' || row?.PRICE_TYPE?.trim() == 'SqFt'?getSqrt(boothObject):1)).toFixed(2),
                booth: booths[i],
                type: row.PRICE_CATEGORY,
                boothObject: boothObjects[i],
                default: true,
                discountFlag: row.PRICE_CATEGORY=='discount'
              }
            )
          }
        }

        newBoothSaleItems.push(
          options
        )
      }
      setBoothSaleItems(newBoothSaleItems);
    }
  }
  const handleCloseSnack = ()=>{
    setOpenSnack(false);
}

  const doSearch = (name)=>{
    

    if(name && name != ''){
      if(timeout)
        clearTimeout(timeout);
      setLoadingFlag(true)
      timeout = setTimeout(async ()=> {        
        var url = `${ESHOW_URL()}?method=getCompany&token=${getTokenCookie()}&search_type=subexhibitor&company_name=${name}`
        axiosInstance.get(url).then(res=>{
          setLoadingFlag(false)
          if(g_searchVal && g_searchVal!=''){
            if(res.data){
              console.log(res.data)
              var result = res.data
              if(result)
                serSearchedList(result)
              else{
                serSearchedList([])
              }
            }
          }
          else{
            serSearchedList([])
            setOpenDropdown(false)
          }
          
        }).catch(error => {
            console.log("error", error);
        })
        setOpenDropdown(true)
      }, 300);
    }
    else{
      serSearchedList([])
      setOpenDropdown(false)
    }
  }

  const doSearchContact = (name = null)=>{
    setLoadingFlag(true)
    if(timeout)
      clearTimeout(timeout);
    timeout = setTimeout(async ()=> {
      if(name && name!= ''){
        var tmp = [];
        for(var i = 0; i < allContacts.length; i++){
          if(((allContacts[i].FIRST_NAME?allContacts[i].FIRST_NAME.trim():"")+" "+(allContacts[i].LAST_NAME?allContacts[i].LAST_NAME.trim():"")).includes(name)){
            tmp.push(allContacts[i])
          }
        }
        setContacts(tmp)
      }
      else{      
        setContacts(allContacts);
      }
      setLoadingFlag(false)
    }, 300);
  }

  useEffect(() => {
    if(props.boothTexts && props.boothObjects){
      updateBoothSaleItems(props.boothTexts, props.boothObjects)
    }
  }, [props.boothTexts])
  
  useEffect(() => {
    var newMergedBooth = '';
    var newMergedBoothObject = false;
    if(autoCombine){ //groupping
      var booths = props.boothTexts;
      var boothObjects = props.boothObjects;
      if(booths && booths.length > 0){
        newMergedBooth = booths[0]
        if(boothObjects && boothObjects.length > 0){
          for(var i = 0; i < boothObjects.length; i++){
            if(newMergedBooth == boothObjects[i].booth_number){
              newMergedBoothObject = boothObjects[i];
              break;
            }
          }
        }
        groupItemsPreview(newMergedBooth)
      }
      updateBoothSaleItems([newMergedBooth], [newMergedBoothObject]);
    }
    else{
      updateBoothSaleItems(props.boothTexts?props.boothTexts:[], props.boothObjects?props.boothObjects:[]);
    }
  }, [autoCombine])

  const insertBoothSaleInfo = (boothIndex, rowIndex)=>{ 
    var booths = props.boothTexts
    var boothObjects = props.boothObjects
    var emptyrow = {
      saleitem_name: null,
      saleitem_price: 0,
      saleitem_total: 0,
      saleitem_qty: 0,
      saleitem_total: 0,
      booth: booths[boothIndex],
      boothObject: boothObjects[boothIndex]
    }   
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows = [...rows.slice(0, rowIndex), emptyrow, ...rows.slice(rowIndex)]
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }
  const removeBoothSaleInfo = (boothIndex, rowIndex)=>{
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows = [...rows.slice(0, rowIndex), ...rows.slice(rowIndex+1)]
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }

  const updateBoothSaleInfo = (boothIndex, rowIndex, key, value)=>{    
    var newBoothSaleItems = [...boothSaleItems];
    var rows = [...newBoothSaleItems[boothIndex]]
    rows[rowIndex]= {...rows[rowIndex], [key]:value} ;
    if(key == "saleitem_id" && value){
      var saleitems = getBoothSaleItems()
      for(var i = 0; i < saleitems.length; i++){
        if(saleitems[i].KEY_ID == value.KEY_ID){
          var price_type = saleitems[i]['COLUMNS']?.[0]?.PRICE_TYPE
          rows[rowIndex]= {
            ...rows[rowIndex], 
            saleitem_name:saleitems[i].name, 
            saleitem_id:saleitems[i].KEY_ID,
            saleitem_product_id:saleitems[i].PRODUCT_KEY, 
            saleitem_price:saleitems[i]['COLUMNS']?.[0]?.PRICE, 
            saleitem_qty:1, 
            saleitem_total:((price_type?.trim() == 'square ft' || price_type?.trim() == 'sq_ft' || price_type?.trim() == 'SqFt'?getSqrt(rows[rowIndex]['boothObject']):1)*saleitems[i]['COLUMNS']?.[0]?.PRICE).toFixed(2),
            price_type:price_type, 
            discountFlag: saleitems[i].discountFlag, 
            type: saleitems[i].type
          } ;
        }
      }
    }
    newBoothSaleItems[boothIndex] = rows;
    setBoothSaleItems(newBoothSaleItems);
  }

  const getSqrt= (booth)=>{
    if(!booth){
      return 0
    }
    var convertRateX= 1
    var convertRateY= 1
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var booth_width = 0
    var booth_height = 0
    if(px2unit(booth.width, convertRateX, mainCanvas.mapUnit) >= 5){
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    else{
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    var sqrt = 0;
    if(booth.class1 == "custom-booth"){
        var objects = booth.getObjects('polyline');
        if(objects && objects.length > 0 ){
            var points = objects[0].points;
            var polyline = [];
            for(var i = 0; i < points.length; i++){
                polyline.push([px2unit(points[i].x, convertRateX, mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, mainCanvas.mapUnit)])
            }
            sqrt = area(polyline)
        }
    }
    else{
        sqrt = (booth_width*booth_height)
    }
    return sqrt
  }

  const getBoothSize= (booth)=>{
    if(!booth){
      return 0
    }
    var convertRateX= 1
    var convertRateY= 1
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var booth_width = 0
    var booth_height = 0
    if(px2unit(booth.width, convertRateX, mainCanvas.mapUnit) >= 5){
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    else{
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    return{
      booth_width, booth_height
    }
  }

  
  
  const handleAutoCombine=(value)=>{
    if(value){ //groupping
      var booths = props.boothTexts;
      if(booths && booths.length > 0){
        props.setGrouppingBooth(booths[0])
        return props.groupItems(booths[0], true);
      }
    }
    else{ //ungroupping
      
    }
    // setAutoCombine(value)
    return false
  }

  useEffect(() => {
    if(step == 4){
      fetchSaleitems();
    }
    if(step == 1){
      groupItemsPreview();
    }
  }, [step])
  

  const fetchSaleitems = ()=>{
    // axiosInstance.get('/getsaleitems').then(res=>{
    //   if(res.data){
    //     console.log(res.data)
    //     setSaleitems(res.data);
    //   }
    // }).catch(error => {
    //     console.log("error", error);
    // })
  }
  
    
  const fetchContacts = (company_key)=>{
    if(company_key){
      axiosInstance.get(`${ESHOW_URL()}?method=getContact&token=${getTokenCookie()}&crm_company_key=${company_key}`).then(res=>{
        if(res.data){
          console.log(res.data)
          var result = res.data
          if(result){
            setAllContacts(result)
            setContacts(result)
          }
          else{
            setAllContacts([])
            setContacts([])
          }
        }
      }).catch(error => {
          console.log("error", error);
      })
    }
    else{      
      setAllContacts([])
      setContacts([]);
      setContact({
      })
    }
  }

  const setCompanyValue = (key, value)=>{
    var newCompany;
    if(company)
      newCompany = {...company, [key]:value}
    else
      newCompany = {[key]:value}
    setCompany(newCompany)
  }

  const setContactValue = (key, value)=>{
    var newContact;
    if(contact)
    newContact = {...contact, [key]:value}
    else
    newContact = {[key]:value}
    setContact(newContact)
  }


  const getCountryObject = (code)=>{
    for(var i=0; i<countries.length; i++){
      if(countries[i].code == code){
        return countries[i];
      }
    }
    return null;
  }
  
  const onChangeName = (e)=>{
    onChange(e)
  }

  const onOk = async ()=>{
    if(!company){
      setOpenSnack(true);
      setSnackMsg("Company is required");
      return;
    }
    if(!contact){
      setOpenSnack(true);
      setSnackMsg("Contact is required");
      return;
    }
    if(!company.COMPANY_NAME){
      setOpenSnack(true);
      setSnackMsg("Company Name is required");
      return;
    }
    if(!company.ZIP_CODE){
      setOpenSnack(true);
      setSnackMsg("Company Zip is required");
      return;
    }
    if(!contact.FIRST_NAME){
      setOpenSnack(true);
      setSnackMsg("Contact First name is required");
      return;
    }
    if(!contact.LAST_NAME){
      setOpenSnack(true);
      setSnackMsg("Contact Last name is required");
      return;
    }
    if(!contact.EMAIL){
      setOpenSnack(true);
      setSnackMsg("Contact Email is required");
      return;
    }
    
    const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    var newCompany = {...company}

    var newContact = {...contact}
    newContact.PARENT_KEY = newCompany.KEY_ID;

    var grouppedItem = handleAutoCombine(autoCombine);
    var boothFileList = props.main.boothFileList;
    var activeFile
    for(var i = 0; i <boothFileList.length; i++){
        if(boothFileList[i]['active']){
            activeFile = boothFileList[i];
            break;
        }
    }
    if(activeFile){
      var formdata = 
      {
          company:newCompany,
          contacts:newContact?[newContact]:[],
          sales_form:sales_form,
          booth_sale_items: boothSaleItems,
          booth_id: activeFile['id'],
          boothObjects
      }
      console.log(formdata);
      props.onOk(formdata, selectedCoBooths)
    }
  }

  const getBoothSaleItems = ()=>{
    var options = [];
    var pricingOptions = props.main.pricingOptions
    var premiumOptions = props.main.premiumOptions
    var discountOptions = props.main.discountOptions
    var total = 0;

    {
      for(var j = 0; j < pricingOptions.length; j++){
        var pricingOption = pricingOptions[j]
        for(var k = 0; k < pricingOption['ROWS']?.length; k++){
          var row = pricingOption['ROWS']?.[k]
          row.type = "Price"
          row.PRODUCT_KEY = pricingOption['KEY_ID']
          row.name = `${pricingOption['TITLE']?.trim()} ${row['ROW_TITLE']?.trim()}`
          options.push(row)
        }
      }
      for(var j = 0; j < premiumOptions.length; j++){
        var pricingOption = premiumOptions[j]
        for(var k = 0; k < pricingOption['ROWS']?.length; k++){
          var row = pricingOption['ROWS']?.[k]
          row.type = "Premium"
          row.PRODUCT_KEY = pricingOption['KEY_ID']
          row.name = `${pricingOption['TITLE']?.trim()} ${row['ROW_TITLE']?.trim()}`
          options.push(row)
        }
      }
      for(var j = 0; j < discountOptions.length; j++){
        var pricingOption = discountOptions[j]
        for(var k = 0; k < pricingOption['ROWS']?.length; k++){
          var row = pricingOption['ROWS']?.[k]
          row.type = "Discount"
          row.discountFlag = true
          row.PRODUCT_KEY = pricingOption['KEY_ID']
          row.name = `${pricingOption['TITLE']?.trim()} ${row['ROW_TITLE']?.trim()}`
          options.push(row)
        }
      }
    }
    return options;
  }


  var booths = props.boothTexts;
  console.log("contacts", contacts)
  console.log("company", company)
  console.log("boothSaleItems", boothSaleItems)
  var total = 0;
  if(boothSaleItems){
    for(var i = 0; i < boothSaleItems.length; i++){
      if(boothSaleItems[i] && boothSaleItems[i].length > 0){
        for(var j = 0; j < boothSaleItems[i].length; j++){
          if(boothSaleItems[i][j].discountFlag){
            total-= boothSaleItems[i][j].saleitem_total*1
          }
          else{
            var boothSaleItem = boothSaleItems[i]
            var row = boothSaleItems[i][j]
            total+= row.saleitem_total*1
          }

        }
      }
    }
  }

  var mainCanvas = props.main.mainCanvas
  var objects = props.main.mainCanvas.getObjects('group')
  var groups = mainCanvas.getObjects('group');
  var map_companies = [];
  for(var i = 0; i < groups.length; i++){
      var group = groups[i]
      if(group.class == 'booth' && group.sold){
        var exhibitors = group?.exhibitors
        if(exhibitors && exhibitors.length > 0){
          map_companies.push(exhibitors[0].COMPANY_KEY)
        }
      }
  }
  console.log("map_companies", map_companies)
  
  const active_booth_file = props.main?.active_booth_file
  const form_key = active_booth_file?.sales_form 
  return (
    <Paper elevation={3} style={{padding:'20px', marginTop:20}}>
      <Box>
        
        {(step == 1) &&
        <Box mt={2}>
          {form_key && form_key != "" ?
            <>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked checked={autoCombine} onChange={(e)=>setAutoCombine(e.target.checked)}/>} label="Auto-Combine Booths" />
              </FormGroup>
              <List>
                {
                  booths.map((booth, index)=>{
                    return(
                      <ListItem key = {index} disablePadding>
                        {/* <ListItemButton> */}
                          <ListItemText primary={"Booth: "+booth} />
                        {/* </ListItemButton> */}
                      </ListItem>
                    )
                  })
                }
              </List>
              {autoCombine &&
              <div id="previewSvg" style={{marginTop: 20, width:'100%', height:'200px'}}>
                
              </div>
              }
            </>:
            <>
                <ErrorIcon  sx={{ color: pink[500] }} fontSize="large"/>
                <div style={{color: `${pink[500]}`}}>
                  Form Key is not Setup
                </div>
            </>
          }
        </Box>
        }
        {(step == 2) &&
        <Box mt={2}>
          {form_key && form_key != "" ?
          <>
            <h4 style={{marginBottom: '5px'}}>Select Company</h4>
            <Box style={{position:'relative'}} mb={3}>
              <Box style={{border:'1px solid #e0e0e0', borderRadius:'4px'}}>
                <InputBase
                    sx={{ ml: 1, flex: 1, width:'calc(100% - 50px)' }}
                    placeholder="Search company"
                    inputProps={{ 'aria-label': 'search booth' }}
                    value={searchVal}
                    onChange={
                      (e)=>{
                        // if(e.target.value == "" || !e.target.value)
                        {
                          setSearchVal(e.target.value)
                          doSearch(e.target.value)
                        }
                      }
                    }
                />
                <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                    <SearchIcon />
                </IconButton>
              </Box>
              <Box mt={3} sx={{minHeight:'200px', maxHeight:'400px', overflowY:'auto'}}>
                <Grid container spacing={2}>
                  <Grid item xs={4}
                      onClick={
                        ()=>{
                          var tmp = {KEY_ID:uuid4(),new:true}
                          setCompany({...tmp})                          
                          setOrgCompany({...tmp})
                          var tmp1 = {KEY_ID:uuid4(),new:true}
                          setContact({...tmp1})
                          setOrgContact({...tmp1})
                          setContacts([])
                          setStep(step+2)
                        }
                      }
                    > 
                      <Box className={`company-tile ${company?.new?"selected":""}`} p={2}>
                        <label style={{wordBreak:'break-all', cursor: 'pointer'}}>New Company</label>
                        <Box sx={{textAlign:'center'}}>
                          <AddCircleOutlineIcon sx={{marginTop: '3px'}} fontSize="small"/>
                        </Box>
                        
                      </Box>
                  </Grid>
                  {searchedList.map((row, index) => (
                    <Grid item xs={4}
                      key={index}
                      onClick={()=>{
                        var tmp = {
                          'KEY_ID' : row['KEY_ID'],
                          'COMPANY_NAME': row['COMPANY_NAME'],
                          'ADDRESS1':row['ADDRESS1'],
                          'ADDRESS2':row['ADDRESS2'],
                          'CITY':row['CITY'],
                          'STATE':row['STATE'],
                          'ZIP_CODE':row['ZIP_CODE']
                        }
                        setCompany({...tmp});
                        setOrgCompany({...tmp});
                        setSearchVal(row['COMPANY_NAME']?row['COMPANY_NAME'].trim():""); 
                        setStep(step+1)
                        fetchContacts(row['KEY_ID'])
                        setContact({})
                      }} 
                      sx={{paddingTop: '4px', paddingBottom:'4px', cursor:'pointer'}}
                    > 
                      <Box className={`company-tile ${company?.KEY_ID == row['KEY_ID']?"selected":""}`} p={2} style={{background:(map_companies.includes(row['KEY_ID'])?'#65e865':'inherit')}}>
                        <label style={{wordBreak:'break-all'}}>{row['COMPANY_NAME']}</label><br/>
                        <BusinessIcon sx={{marginTop: '3px'}} fontSize="small"/>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </>:
          <>
              <ErrorIcon  sx={{ color: pink[500] }} fontSize="large"/>
              <div style={{color: `${pink[500]}`}}>
                Form Key is not Setup
              </div>
          </>
          }
        </Box>
        }
        {(step == 3) &&
        <Box mt={2}>
          <h4 style={{marginBottom: '3px'}}>Select Contact</h4>
          <Box style={{position:'relative'}} mb={3}>
            <Box mt={1} sx={{height:'200px'}}>
              <Grid container spacing={2}>
                <Grid item xs={4}
                    onClick={()=>{
                      setContacts([])
                      var tmp = {KEY_ID:uuid4(),new:true}
                      setContact({...tmp})
                      setOrgContact({...tmp})
                      setStep(step+1)
                    }
                  }
                  > 
                    <Box className={`company-tile ${company?.new?"selected":""}`} p={2}>
                      <label style={{wordBreak:'break-all'}}>New Contact</label>
                      <Box sx={{textAlign:'center'}}>
                        <AddCircleOutlineIcon sx={{marginTop: '3px'}} fontSize="small"/>
                      </Box>
                      
                    </Box>
                </Grid>
                {contacts.map((row, index) => (
                  <Grid item xs={4}
                    key={index}
                    onClick={()=>{
                        setContact({...row});
                        setOrgContact({...row});
                        setSearchContactVal((row['FIRST_NAME']?row['FIRST_NAME'].trim():"")+" "+(row['LAST_NAME']?row['LAST_NAME'].trim():"")); 
                        setStep(step+1)
                      }
                    } 
                    sx={{paddingTop: '4px', paddingBottom:'4px', cursor:'pointer'}
                    }
                  > 
                    <Box  className={`company-tile ${contact?.KEY_ID == row['KEY_ID']?"selected":""}`} p={2}>
                      <label style={{wordBreak:'break-all'}}>{(row['FIRST_NAME']?row['FIRST_NAME']:"")+" "+(row['LAST_NAME']?row['LAST_NAME']:"")}</label><br/>
                      <BusinessIcon sx={{marginTop: '3px'}} fontSize="small"/>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
        }
        {(step == 4) &&
        <Box>
          <Box mt="3">
            <h4 style={{marginBottom:0}}>Company</h4>
            <Grid container spacing={2}>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Company Name:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="COMPANY_NAME"
                  margin="dense"
                  label=""
                  disabled = {!company.new}
                  fullWidth
                  variant="standard"
                  value = {company?company.COMPANY_NAME:""}
                  onChange={(e)=>(setCompanyValue('COMPANY_NAME', e.target.value))}
                  size="small"
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Address1:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="ADDRESS1"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.ADDRESS1:""}
                  onChange={(e)=>(setCompanyValue('ADDRESS1', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Address2:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="ADDRESS2"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.ADDRESS2:""}
                  onChange={(e)=>(setCompanyValue('ADDRESS2', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>City:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="CITY"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.CITY:""}
                  onChange={(e)=>(setCompanyValue('CITY', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>State:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="STATE"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.STATE:""}
                  onChange={(e)=>(setCompanyValue('STATE', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
              <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
                <label sx={{fontWeight:'bold', fontSize:20}} shrink>Zip:</label>
              </Grid>
              <Grid item xs={8}> 
                <TextField
                  name="ZIP_CODE"
                  margin="dense"
                  label=""
                  
                  fullWidth
                  variant="standard"
                  value = {company?company.ZIP_CODE:""}
                  onChange={(e)=>(setCompanyValue('ZIP_CODE', e.target.value))}
                  size="small"
                  // onChange={}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        }
        {(step == 4) &&
        <Box mt={3}>
          <h4 style={{marginBottom:0}}>Contact</h4>
          <Grid container spacing={2}>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Salutation:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="SALUTATION"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.SALUTATION:""}
                onChange={(e)=>(setContactValue('SALUTATION', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>First name<span style={{color:'red'}}>*</span>:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="FIRST_NAME"
                margin="dense"
                label=""
                size = "small"
                disabled = {!contact?.new && (contact?.FIRST_NAME && contact?.FIRST_NAME !="")}
                fullWidth
                variant="standard"
                value = {contact?contact.FIRST_NAME:""}
                onChange={(e)=>(setContactValue('FIRST_NAME', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Last name<span style={{color:'red'}}>*</span>:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="LAST_NAME"
                margin="dense"
                label=""
                size = "small"
                disabled = {!contact?.new && (contact?.LAST_NAME && contact?.LAST_NAME !="")}
                fullWidth
                variant="standard"
                value = {contact?contact.LAST_NAME:""}
                onChange={(e)=>(setContactValue('LAST_NAME', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Title:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="TITLE"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.TITLE:""}
                onChange={(e)=>(setContactValue('TITLE', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Email<span style={{color:'red'}}>*</span>:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="EMAIL"
                margin="dense"
                label=""
                size = "small"
                disabled = {!contact?.new && (contact?.EMAIL && contact?.EMAIL !="")}
                fullWidth
                variant="standard"
                value = {contact?contact.EMAIL:""}
                onChange={(e)=>(setContactValue('EMAIL', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}> 
              <label sx={{fontWeight:'bold', fontSize:20}} shrink>Phone:</label>
            </Grid>
            <Grid item xs={8}> 
              <TextField
                name="PHONE"
                margin="dense"
                label=""
                size = "small"
                fullWidth
                variant="standard"
                value = {contact?contact.PHONE:""}
                onChange={(e)=>(setContactValue('PHONE', e.target.value))}
                InputLabelProps = {{shrink: true}}
                // onChange={}
              />
            </Grid>
           
          </Grid>
        </Box>
        }
        {(step == 4) &&
        <Box mt={3}>
          <h4>Booths</h4>
          <Table  aria-label="simple table">
            <TableBody>     
              {
                coBooths.map((booth, index2) => {
                  return(                   
                    <TableRow
                      key = {index2}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{padding:'5px', width: '40px'}} component="th" scope="row">
                        <input type="checkbox" value={true} checked={selectedCoBooths.some(el => el.BOOTH_KEY === booth.BOOTH_KEY)}
                          onChange={(e)=>{
                            var tmp = [];
                            if(selectedCoBooths.some(el => el.BOOTH_KEY === booth.BOOTH_KEY)){
                              tmp = selectedCoBooths.filter(function( obj ) {
                                return obj.BOOTH_KEY !== booth.BOOTH_KEY;
                              });
                            }
                            else{
                              tmp = [...selectedCoBooths, booth]
                            }
                            setSelectedCoBooths(tmp)
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{padding:'5px', textAlign:'center'}}>
                        <Box pt={1} pb={1}>Booth Number {booth.BOOTH_NO}</Box>
                      </TableCell>
                    </TableRow>     
                  )
                })
              }             
            </TableBody>
          </Table>
        </Box>
        }
        <LoadingOverlay
          active={loadingFlag}
          styles={{
            wrapper:{
              position: 'absolute',
              width: '100px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },

              // spinner: (base) => ({
              // ...base,
              // width: '50px',
              // '& svg circle': {
              //     stroke: '#1976d2'
              // }
              // }),
            overlay: (base) => ({
              ...base,
              fontSize:'18px',
              color: 'rgb(5, 37, 51)',
              // background: 'rgb(229 229 229 / 92%)',
              background: 'transparent',
              position:'absolute',
              zIndex:1000000000
            })
          }}
          spinner = {
          <ScaleLoader 
              color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'}
              radius={2.5} 
              margin={2.5} 
              height={40} 
              width={5}
          />}
          // spinner
          // text='Loading ...'
          >
      </LoadingOverlay> 
      </Box>
      <Box style={{textAlign:'right'}}>
        {step >1 && 
          <Button 
            disabled={!form_key || form_key == ""}
            onClick={()=>{
            if(step == 2){
              setStep(2)
            }
            else
             setStep(step-1)
           }
         }>Back</Button>
        }
        {step < 4 ?
          <Button
            disabled={!form_key || form_key == "" || (!company?.KEY_ID && step==2) || (!contact?.KEY_ID && step==3)}
            onClick={()=>{
             if(step == 1){
              // if((props.boothTexts&& props.boothTexts.length > 1)){
              //   setStep(step+1)
              // }
              // else{
              //   setStep(2)
              // }
              
              setStep(2)
             }
             else
              setStep(step+1)
            }
          }>Next</Button>:
          <Button onClick={onOk}>Ok</Button>
        }
        <Button onClick={onClose}>Cancel</Button>
      </Box>
      <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
    </Paper>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) 
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SubExhibitorModal);