import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
// import { FixedSizeList as FixedSizeList } from 'react-window';
import {List as FixedSizeList } from 'react-virtualized';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { getThemeProps } from '@mui/system';
import { setLeftSearchVal, setOpenBoothList, setLeftPage, setOpenSetBoothModal } from '../../../actions/ui'
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Button from '@mui/material/Button';
import CompanyEditModal from '../CompanyEditModal'
import { useEffect,useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import  area  from 'area-polygon'
import { setActiveObject, px2unit, unit2Px } from '../../../utils/CanvasTool'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setActive3dObject } from '../../../actions/main';
import Fade from '@mui/material/Fade';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  width: '38px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function BoothList(props) {
  const theme = useTheme();
  const [boothList, setBoothList] = React.useState([]);
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [filteredBoothList, setFilteredBoothList] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState(null);
  
  var mainCanvas = props.main.mainCanvas
  
  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    var booth = filteredBoothList[index]
    if(booth){
      return (
        <ListItem key={key} style={style}
          onClick={()=>{setActiveObject(booth.booth, props.main); props.setActive3dObject(booth.booth)}}
          sx={{cursor:'pointer'}}         
        >
          <ListItemText 
            primary={
              <Box display="flex" style={{justifyContent:'space-between'}}>
                <Box style={{width: '40%', textAlign:'left'}}><label>{booth.name}</label></Box>
                <Box style={{width: '30%', textAlign:'left'}}><label>{(booth.boothsize)}</label></Box>
                <Box style={{width: '30%', textAlign:'center'}}>{booth.sqrt}</Box>
              </Box>
            } 
          />
          
        </ListItem>
        // <ListItem key={key} style={style}>
        //   123
        // </ListItem>
        // <ListItem key={key} style={style}
        //   // onClick={()=>{setActiveObject(booth.booth, props.main); props.setActive3dObject(booth.booth)}}
        //   // sx={{cursor:'pointer'}}
        // >
        //   123
          
        // </ListItem>
      );
    }
    else{
      return (<></>)
    }
  }

  useEffect(() => {
    if(props.ui.openBoothList){
      setLoadingFlag(true)
      setTimeout(function(){
        if(props.main.mainCanvas){
          var boothList = [];
          var objects = props.main.mainCanvas.getObjects('group');
          var convertRateX= 1
          if(props.main.mainCanvas.convertRateX) {
              var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
          }
          for(var i = 0; i< objects.length; i++){
            var object = objects[i]
            var row = {}
            if(object.get('class')=="booth" && object.layer != "deleted"){
              row['name'] = object.booth_number
              row['priced'] = false
              var priceCheck = object.get('priceCheck');
              var premiumCheck = object.get('premiumCheck');
              if(premiumCheck){
                row['priced'] = true
              }
              if(priceCheck && priceCheck.length > 0){
                row['priced'] = true
              }
              if(object.get('sold')){
                row['priced'] = true
              }
              row['company'] = object.get('company')
              row['boothgroup'] = object.get('boothGroup')
              row['boothType'] = object.get('boothType')
              var booth_width = px2unit(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
              var booth_height = px2unit(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
              row['boothsize'] = booth_width+" x "+ booth_height
              var sqrt = 0;
              if(object.class1 == "custom-booth"){
                var objects = object.getObjects('polyline');
                if(objects && objects.length > 0 ){
                    var points = objects[0].points;
                    var polyline = [];
                    for(var i = 0; i < points.length; i++){
                      polyline.push([px2unit(points[i].x, convertRateX, mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, mainCanvas.mapUnit)])
                    }
                    sqrt = area(polyline)
                }
              }
              else{
                  sqrt = (booth_width*booth_height)
              }
              row['sqrt'] = (sqrt*1).toFixed(0)
              row['booth'] = object
             if(row['name'])
              boothList.push(row)
            }
          }
        }
      
        boothList.sort(function(a, b) {
          var a_val = (a.name)?a.name:''
          var b_val = (b.name)?b.name:''
          return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
        });

        if(searchVal){
          var filteredBoothList = boothList.filter((booth)=>{
            var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
            return (str.toLowerCase().includes(searchVal.toLowerCase()))
          })
          setFilteredBoothList(filteredBoothList)
        }
        else{
          setFilteredBoothList(boothList)
        }

        setBoothList(boothList)
        
        setLoadingFlag(false)
      }, 100)
    }
  }, [props.ui.openBoothList,props.main.canvasRefresh])


  const doSearch = (searchVal)=>{
    if(searchVal){
      var filteredBoothList = boothList.filter((booth)=>{
        var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
        return (str.toLowerCase().includes(searchVal.toLowerCase()))
      })
      setFilteredBoothList(filteredBoothList)
    }
    else
      setFilteredBoothList(boothList)
  }

  console.log("booth list")
  var listHeight = window.innerHeight - 195
  const drawerWidth = Math.min(Math.max(410, Math.round(window.innerWidth*0.33)), window.innerWidth);
  return (
    <Fade in={true} timeout={1200}>
      <div className='appLeftSearch'>
        <Box className="">
            <Box display="flex" pt={2} pb={2} style={{width:'100%', justifyContent:'space-between'}}>
                
                <div style={{marginLeft:20}}></div>
                <InputBase
                    sx={{ ml: 1, flex: 1, border: '1px solid #e3e3e3', padding: '3px 7px', borderRadius: '3px' }}
                    placeholder="Search company or booth"
                    inputProps={{ 'aria-label': 'search booth' }}
                    // value={searchVal}
                    onBlur={
                        (e) => {
                            setSearchVal(e.target.value)
                        }
                    }
                    onKeyDown={
                        (e)=>{
                            if(e.keyCode == 13){
                                e.preventDefault();
                                doSearch(e.target.value)
                            }
                        }
                    }
                    onChange={
                      (e)=>{
                        if(e.target.value == "" || !e.target.value){
                          doSearch(e.target.value)
                        }
                      }
                    }
                />
                <IconButton size="small" type="button" onClick = {()=>doSearch(searchVal)} aria-label="search">
                    <SearchIcon />
                </IconButton>
              <div style={{display:'flex', alignItems:'center', paddingRight:3}}>
                {props.role == 'admin' ?          
                <IconButton
                    size="small"
                    edge="start"
                    aria-label="assign"
                    style={{float:'right'}}
                    onClick={()=>{props.setOpenBoothList(false)}}
                  >
                    <HighlightOffOutlinedIcon/>
                </IconButton>:
                <IconButton
                  size="small"
                  edge="start"
                  aria-label="assign"
                  style={{float:'right'}}
                  onClick={()=>{props.setLeftPage('home')}}
                >
                  <ChevronLeftIcon/>
                </IconButton>
                }
              </div>
            </Box>
            <Divider />
              <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 16px', borderBottom:'1px solid #e0e0e0', background:(props.ui?.customStyles?.['left-table-header-bg']?props.ui?.customStyles['left-table-header-bg']:'#4e7090'), color:'white'}}>
                <Box style={{width: '40%', textAlign:'left'}}><b>Booth</b></Box>
                <Box style={{width: '30%', textAlign:'left'}}><b>Size</b></Box>
                <Box style={{width: '30%', textAlign:'center'}}><b>{`${props.main?.mainCanvas?.mapUnit == 'feet'?'SqFt':'SqM'}`}</b></Box>
                {/* <Box style={{width: '25%', textAlign:'center'}}><b>Exhibitor</b></Box> */}
              </Box>
              {filteredBoothList&&
              <FixedSizeList
                width={(drawerWidth-(props.role == 'admin'?0:72))}
                height={listHeight}
                rowCount={filteredBoothList.length}
                rowHeight={48}
                rowRenderer={rowRenderer}
              >
              </FixedSizeList>
              }
              {/* {filteredBoothList&&
              <List>
                {
                    filteredBoothList.map((booth, booth_index) => (
                    <ListItem key={booth_index}
                      onClick={()=>{setActiveObject(booth.booth, props.main); props.setActive3dObject(booth.booth)}}
                      sx={{cursor:'pointer'}}
                    >
                      <ListItemText 
                        primary={
                          <Box display="flex" style={{justifyContent:'space-between'}}>
                            <Box style={{width: '40%', textAlign:'left'}}><label>{booth.name}</label></Box>
                            <Box style={{width: '30%', textAlign:'left'}}><label>{(booth.boothsize)}</label></Box>
                            <Box style={{width: '30%', textAlign:'center'}}>{booth.sqrt}</Box>
                          </Box>
                        } 
                      />
                      
                    </ListItem>
                  ))
                }
              </List>
              } */}
            
            <LoadingOverlay
                active={loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'absolute',
                    left:'50%',
                    top:'200px',
                    transform:'translateX(-50%)',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </Box>
      </div>
    </Fade>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLeftSearchVal: value=>dispatch(setLeftSearchVal(value)) ,
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setOpenSetBoothModal:  data=>dispatch(setOpenSetBoothModal(data)),
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(BoothList);
