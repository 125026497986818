import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox';
import { setAuthInfo, setLoading, setBookmarkList, setExhibitorList} from './actions/main'
import { setOpenCompanyProfile } from './actions/ui'
import { setCustomStyles } from './actions/ui'
import { setOpenLogin } from './actions/ui'
import { parseToken, getTokenCookie, getTokenPlanner, setCookie } from './utils/Common'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import qs, { parse } from 'qs'
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import { API_URL, ESHOW_URL } from './config.js'
import ExhibitorCard from  './viewer/component/ExhibitorCard';

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = React.useState(1);
  const [keySelect, setKeySelect] = useState('Keyword')
  const [filteredItems, setFilteredItems] = useState([])
  const [boothFileList, setBoothFileList] = useState([]);
  const page_size = 16;
  const keywordInput = useRef(null);
  
  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const processAuth = async ()=>{      
    var tokenInfo = await parseToken(query)
    await fetchCustomStyles();       
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, SALES,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        return
    }
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url  = `${ESHOW_URL()}?method=getCSS}`
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    }  
    const response = await axios.get(url, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fafafa',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: "#555555",

    borderRadius:0
  }));

  const fetchData = ()=>{
    props.setLoading(true)
    var token = getTokenCookie();   
    try{     
        var _token = getTokenPlanner();
        if(_token){
          token = _token
        }
    }
    catch(e){
      console.log("token parse", e)
    }
    var url = `${ESHOW_URL()}?method=getExhibitorList&featured=yes`
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }   

    axios.get(url, options).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
          props.setExhibitorList(result)
          if(result.EXHIBITORS){
            var boothList = [];
            for(var i = 0; i< result.EXHIBITORS.length; i++){
              var item = result.EXHIBITORS[i]
              if(!item?.SETUP?.LIST_PENDING_EXHIBITORS && item.STATUS == 2)
                continue;
              boothList.push(item)
            }            
            setData(boothList)
            setFilteredItems(boothList)
          }
        }
        else{
          setData([])
          setFilteredItems([])
        }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    props.setOpenCompanyProfile(false)
  }, [])
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key')     
  useEffect(() => {
    if(props.urlToken){
        var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    fetchBookmarkList()
    fetchData()
    fetchboothlist()
  }, [props.main[`planner-${show_key}`], id5, id4, id3, id2, id1])
  
  const openCompanyProfile=(exhibitor)=>{   
    var booth = {};
    booth.exhibitors = [exhibitor]
    booth.booth_number = exhibitor?.BOOTHS

    props.setOpenCompanyProfile({booth:booth, open:true})
  }

  const fetchBookmarkList = ()=>{
  { 
      try{
        var token =  getTokenPlanner();
        if(token){
          var url = `${ESHOW_URL()}?method=getPlanner`
          const options = {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }  
          axios.get(url, options).then(res=>{
              const result = res.data;
              if(result.SUCCESS){
                props.setBookmarkList(result.BOOKMARKS)
              }
              else{

              }
          }).catch(error => {
          })  
        }
      }
      catch(e){
        console.log(e)
      }
    }
  }

  const callBookmarkApi= async (exhibitor, value)=>{    
    const _token = getTokenPlanner();
    var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
    if(value){
      BOOKMARKS = [
        ...BOOKMARKS,
        {
          COMPANY_NAME: exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:"",
          NOTES:"",
          STATUS:'',
          BOOTHS:exhibitor.BOOTHS,
          EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
        }
      ]
    }
    else{
      BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
    }
    props.setBookmarkList(BOOKMARKS)
    var postData =
    {
      SUCCESS:true,
      BOOKMARKS:BOOKMARKS
    };

    const options = {
      'body': '[{"company:","test"}]', 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${_token}`
    }
    var url = `${ESHOW_URL()}?method=putPlanner`
    axios.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS == true){
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to save bookmark");
      }
      // props.setViewFile(filename);
    }).catch(error => {
        console.log("error", error);
    })
  }

  const fetchboothlist=async (save_id=false)=>{   
    var url = `${ESHOW_URL()}?method=getMaps`;
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    axios.get(url, options).then(res=>{
      setBoothFileList(res.data)
    }).catch(error => {
    })      
  }

  const doBookmark = (exhibitor)=>{
    if(exhibitor){
      try{
        const _token = getTokenPlanner();
        if((props.role !='admin') && (!_token || !_token)){
          props.setOpenLogin(true)
          return false;
        }
        var tmp = [...data];
        for(var i = 0; i < tmp.length; i++){
          if(tmp[i].EXHIBITOR_KEY == exhibitor.EXHIBITOR_KEY){
            tmp[i].BOOKMARKED = !tmp[i].BOOKMARKED
            callBookmarkApi(exhibitor, tmp[i].BOOKMARKED)
          }
        }
        this.setData(tmp);
        // var tmp1 = [...filteredItems];
        // for(var i = 0; i < tmp1.length; i++){
        //   if(tmp1[i].EXHIBITOR_KEY == exhibitor.EXHIBITOR_KEY){
        //     tmp1[i].BOOKMARKED = !tmp1[i].BOOKMARKED
        //     callBookmarkApi(exhibitor, tmp1[i].BOOKMARKED)
        //   }
        // }
        // this.setFilteredItems(tmp1)
      }
      catch(e){

      }
    }
  }

  console.log("render client app")
  console.log("data", filteredItems);
  var pageData = paginate(filteredItems, page_size, page);
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  var sales_bookmarks_show = true
  if(props.main.authInfo && props.main.authInfo.SALES){
    if(props.main.authInfo.SALES.BOOKMARKS == 'hide'){
      sales_bookmarks_show = false
    }
  }
  var outputItems = pageData.sort((a, b) => `${a.COMPANY_NAME}`.localeCompare(`${b.COMPANY_NAME}`));
  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1240, margin:'64px auto', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>
        
        <Box mt={2}>
          <Grid container spacing={2}>
            {
              outputItems.map((item, index)=>{
                return(
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ExhibitorCard item={item} boothFileList={boothFileList} doBookmark={doBookmark}/>
                  </Grid>
                )
              })
            }
            
            {
              (!filteredItems || filteredItems.length == 0) && dataFetched &&
              <Box style={{textAlign:'center', width:'100%'}}>
                <p>No Featured Exhibitor Found.</p>
              </Box>
            }
          </Grid>
        </Box>
      </Box>
      <Box mt={3} mb={4}>
        <Stack spacing={2}>
          <Pagination variant="outlined" shape="rounded" style={{justifyContent:'center'}} count={Math.ceil(filteredItems.length/page_size)} color="primary"  page={page} onChange={handleChangePage}/>
        </Stack>
      </Box>
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setBookmarkList:  data=>dispatch(setBookmarkList(data)),
      setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
      setExhibitorList:  data=>dispatch(setExhibitorList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
