import * as React from 'react';
import { useEffect,useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { setOpenLogin, setOpenCompanyProfile, setOpenUnassignedProfile, setLeftPage, setOpenBoothList } from '../../actions/ui'
import { convertRgbHex } from '../../utils/Common'
import { setLoading } from '../../actions/main.js'
import { setObjectBG, px2unit, unit2Px } from '../../utils/CanvasTool'
import { addCart } from '../../actions/cart'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'
import { getTokenCookie, getTokenPlanner, getCartCookie } from '../../utils/Common.js'
import BuyModal from './BuyModal'
import qs, { parse } from 'qs'
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
const emails = ['username@gmail.com', 'user02@gmail.com'];
const drawerWidth = Math.min(Math.max(410, Math.round(window.innerWidth*0.33)), window.innerWidth);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  float: 'right',
  marginTop: 12
}));

function AppLeftPanel(props) {
  let query = useQuery();
  const theme = useTheme();
  const [openBuyModal, setOpenBuyModal] = React.useState(false);
  const [reserved, setReserved] = React.useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [priceCheck , setPriceCheck] = React.useState([]);
  const [premiumCheck , setPremiumCheck] = React.useState('');
  const [discountCheck, setDiscountCheck] = React.useState('');
  const [zIndex, setzIndex] = React.useState(1004);

  var pricingOptions = props.main.pricingOptions
  var premiumOptions = props.main.premiumOptions
  var discountOptions = props.main.discountOptions

  var open;
  var booth;
  var boothName;
  var boothSize;
  var bgColor = "#dadada"
  
  if(props.ui.openUnassignedProfile && props.main.mainCanvas){
    booth = props.ui.openUnassignedProfile.booth;    
    boothName = booth.booth_number
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    boothSize = px2unit(booth.width, convertRateX,  props.main.mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")+" x "+px2unit(booth.height, convertRateX,  props.main.mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    open = props.ui.openUnassignedProfile.open;
    
    {
      var boothGroup = booth.boothGroup
      var exhibitors = booth.exhibitors
      var groupOptions = props.groupOptions
      for(var k = 0; k < groupOptions.length; k++){
          if(groupOptions[k].KEY_ID == boothGroup){
              var groupOption = groupOptions[k]
              
              if(booth.sold == '1'){
                bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
              }
              else if((booth.priceCheck && booth.priceCheck.length > 0) || (booth.premiumCheck && booth.premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0)){
                  bgColor = groupOption['AVAILABLE'];
              }
          }
      }
    }
  }
  const handleCloseSnack = ()=>{
      setOpenSnack(false);
  }


  useEffect(() => {
    if(open){      
      setzIndex(10000)
      var activeObject = props.ui.openUnassignedProfile.booth 
      var tmp1 = activeObject.get('premiumCheck')
      if(tmp1){
        setPremiumCheck(tmp1)
      }
      else{
        setPremiumCheck([])
      }
      var tmp2 = activeObject.get('priceCheck')
      if(tmp2){
        setPriceCheck(tmp2)
      }
      else{
        setPriceCheck([])
      }
      var tmp3 = activeObject.get('discountCheck')
      if(tmp3){
        setDiscountCheck(tmp3)
      }
      else{
        setDiscountCheck([])
      }

    }
    else{
      setzIndex(1004)
    }
  }  , [open, props.ui.openUnassignedProfile.booth])

  var activeFile;
  var boothFileList = props.main.boothFileList;
  for(var i = 0; i <boothFileList.length; i++){
      if(boothFileList[i]['active']){
          activeFile = boothFileList[i];
          break;
      }
  }

  const addToCartList = ()=>{
    booth = props.ui.openUnassignedProfile.booth;
    if(booth){      
      setObjectBG(props.main.mainCanvas, booth, '#00F700')
      props.main.mainCanvas.renderAll()
      props.setOpenUnassignedProfile(false)
      props.setLeftPage('cart')
      props.setOpenBoothList(true)
      var carts = props.cart.carts;
      if(carts && carts.includes(booth)) {
        return;
      }
      props.addCart(booth)
      props.setLeftPage('cart')
      setOpenSnack(true);
      setSnackMsg("Added to Cart");
    }
  }

  const onBuy = (product = null) =>{  
    const _token = getTokenPlanner();
    if((props.role !='admin') && (!_token || !_token)){
      props.setOpenLogin(true)
      return false;
    }
        
    var active_booth_file = props.main.active_booth_file
    var target = props.ui.openUnassignedProfile.booth
    
    var  map_key = active_booth_file.KEY_ID;
    var  booths = target.KEY_ID;
    var  form_key;
    if(props.main.authInfo){
        form_key = active_booth_file.form_key
    }
    var query = `${ESHOW_URL()}?method=purchase_booth&form_key=${form_key}&booths=${booths}&booth_key=${booths}&map_key=${map_key}&token=${getTokenCookie()}`    
    if(target.layer == 'sponsorships'){
        query = `${query}&booth_no=${target.sponsorshipId}`
    }
    if(product){
      query = `${query}&row_id=${product.ROW_ID}`
    }
    var parent_key = props.main.authInfo.SALES?.PARENT_KEY
    if(parent_key && parent_key!= ''){
      query = `${query}&parent_key=${parent_key}`
    }    
    else{
      parent_key = getCartCookie();
      query = `${query}&parent_key=${parent_key}`
    }
    var purchase_url = '';
    purchase_url = props.main.authInfo.SALES?.PURCHASE_URL
    if(purchase_url && purchase_url!= ''){
      query = `${query}&${purchase_url}`
    }

    var url = query;
    var formdata = 
    {  
       query:query              
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    
    props.setLoading(true)  
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        props.setLoading(false)
        if(result.SUCCESS){              
            props.setOpenCompanyProfile(false)
            props.setOpenUnassignedProfile(false)
            // props.setHistorySponsorshipProfile(props.ui.openSponsorshipProfile)            
            props.setOpenBoothList(false);
            setTimeout(()=>{
              props.setOpenBoothList(true);
              props.setLeftPage('sponsor_cart')
            }, 200)
        }
        else{
        }
        if(result.MESSAGE){
            setOpenSnack(true);
            setSnackMsg((result.MESSAGE?(" "+result.MESSAGE):""));
        }
    }).catch(error => {
      props.setLoading(false)
        //console.log("error", error);
    })  
  }


  var total = 0;
  var booth = props.ui.openUnassignedProfile.booth
  if(booth){
    for(var j = 0; j < priceCheck.length; j++){
      var row = priceCheck[j];
      var price = 0;      
      price = row.PRICE*1;
      total+=price
    }
  }
  var sales_shopping_cart_show = false
  var sales_left_drawer_show = true
  if(props.main.authInfo){
    if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'hide'){
      sales_shopping_cart_show = false
      if((premiumCheck&&premiumCheck.length > 0) || (priceCheck&&priceCheck.length > 0)){
        sales_left_drawer_show=false
      }
    }
    else if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'show'){
      sales_shopping_cart_show = true
    }
    if(props.main.authInfo.SALES?.LEFT_DRAWER == 'hide'){
      sales_left_drawer_show = false
    }
    // if(props.main.authInfo.SALES.PURCHASE_BOOTH != 'hide'){
    //   sales_left_drawer_show = false
    // }
  }
  // if(props.role!='admin'){
  //   if(props.main.authInfo.SHOPPING_CART == 'hide'){
  //     sales_left_drawer_show = false
  //   }
  // }
  // sales_shopping_cart_show = true
  // sales_left_drawer_show = true

  return (
    <>    
    {props.main.authInfo.authenticated == 3 && sales_left_drawer_show && sales_shopping_cart_show && Boolean(props.ui.mouseHoverEnable) && 
      <Box className="sideview company-profile-viewer unassigned" sx={{ display: 'flex', zIndex:zIndex}}>
        
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
          className="full-height-slider"
        >
          <Box style={{backgroundColor: bgColor}}>
            <DrawerHeader style={{marginTop:0, display:'flex'}}>
              <IconButton onClick={()=>props.setOpenUnassignedProfile(false)}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Box display='flex' style={{alignItems:'center', justifyContent:'start', paddingLeft: 20, margin: 0, height:'100%'}}>
              <h3 style={{fontSize:20, margin: 0, textAlign:'left'}}>Booth: {boothName}</h3>
            </Box>
          </Box>
          <Divider />
          <Box textAlign="left" p={2}>
            <Box mb={2} display="flex">
              <lable><b>Size and Square {props.main?.mainCanvas?.mapUnit == 'feet'?'Foot':'Meter'}:</b> </lable>
              <lable style={{marginLeft:'10px'}}>{boothSize}</lable>
            </Box>
            {pricingOptions && pricingOptions.length > 0 &&   
            <Box mb={2} >
              <lable><b>Pricing:</b> </lable>
              <Box style={{marginTop:'10px'}}>
                <Table className="pricing-table" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceCheck?.map((row, index) => {
                      if(row.PRICE_CATEGORY == "price")
                      return(
                        <TableRow
                          key={`${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>                      
                            {row.PRICE_LABEL}
                          </TableCell>
                          <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        </TableRow>
                      )
                    })}
                    {priceCheck?.map((row, index) => {
                      if(row.PRICE_CATEGORY == "premium")
                      return(
                        <TableRow
                          key={`${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>                      
                            {row.PRICE_LABEL}
                          </TableCell>
                          <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        </TableRow>
                      )
                    })}
                    {priceCheck?.map((row, index) => {
                      if(row.PRICE_CATEGORY == "discount")
                      return(
                        <TableRow
                          key={`${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>                      
                            {row.PRICE_LABEL}
                          </TableCell>
                          <TableCell>(${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>(${(row.PRICE*1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            }
            <Box mb={2}>
              <p style={{textAlign:'right', paddingRight:'56px'}}><strong>Total:</strong> ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            </Box>
            <Box mb={2}>
              <lable><b>Special Notes:</b> </lable>
              <div style={{marginTop:'10px'}}> </div>
            </Box>
            {(premiumCheck.length > 0 || priceCheck.length > 0) && sales_shopping_cart_show &&
            <Box textAlign="center" display="block">  
              <Box mb={2}>
                <Button style={{width:150}} variant="contained"  onClick={()=>{onBuy();}}>
                  Add to cart
                </Button>
              </Box>
              {/* <Box mb={2}>
                <Button style={{width:150}} variant="contained" onClick={()=>setOpenBuyModal(true)} >
                  Buy
                </Button>
              </Box> */}
              {/* <Box mb={2}>
                <Button style={{width:150}} variant="contained"  onClick={()=>{setOpenBuyModal(true); setReserved(true)}}>
                  Reserve
                </Button>
              </Box> */}
            </Box>
            }
          </Box>
        </Drawer>
        <BuyModal
          open={openBuyModal}
          onClose={()=>{setOpenBuyModal(false);setReserved(false)}}
          booths = {[booth]}
          boothTexts = {[boothName]}
          reserved = {reserved}
        />
        
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            message={snackMsg}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
      </Box>
    }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    cart: state.cart,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setOpenUnassignedProfile:  data=>dispatch(setOpenUnassignedProfile(data)),
    setOpenBoothList:  data=>dispatch(setOpenBoothList(data)),
    addCart:  data=>dispatch(addCart(data)),
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setLoading:  data=>dispatch(setLoading(data)),
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
  };
};

export default connect(mapStateToProps, mapDispatchProps)(AppLeftPanel);
