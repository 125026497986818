import React ,{ useEffect, useRef, useState } from 'react';
import axios from 'axios';
import qs, { parse } from 'qs'
import jQuery from "jquery";
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import { connect } from 'react-redux'
import './View.css';
import ToolBox from './ToolBox';
import BoothList from './component/BoothList';
import ElementBox from './component/ElementBox';
import SetBoothModal from './component/SetBoothModal'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import LoadingOverlay from 'react-loading-overlay';
import * as THREE from 'three';
import { OrbitControls, MapControls } from '../utils/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import polygonClipping from 'polygon-clipping'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ScaleLoader from 'react-spinners/ScaleLoader'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import SpriteText from 'three-spritetext';
import ListIcon from '@mui/icons-material/List';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import AppLeftPanel from '../viewer/component/AppLeftPanel';
import { renderBooth, markActiveBooth } from '../utils/Common3d'
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { getTokenCookie } from '../utils/Common'


import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";
import { API_URL } from '../config.js'
import { setMainCanvas, setMainLayers, setBoothList, setBoothFileList, setCanvasRefresh, setLoading, to2D, setActive3dObject } from '../actions/main'
import { setBoothHistory } from '../actions/booth_history'
import Flatten from "@flatten-js/core"
const { polygon } = Flatten;
const { unify } = Flatten.BooleanOperations;


function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

var draggingTarget = null
var scene, camera, renderer, exporter, mesh, meshes = [];
var assetsPath = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/2666677/';
assetsPath = "/"
var players = [];
var player = { };
var animations = {};
var colliders = [];
var clock = new THREE.Clock();
var animateId;
var boothModel;
var table6Model;
var table8Model;
var table10Model;
var controls;
var font;
var interval;

var g_rotateLeft = false;
var g_rotateRight = false
var g_initDistance = 0;
var g_mapWidth
var g_mapHeight
function MainViewer(props) {
    const mainCanvasRef = useRef(null);
    const [mainSvg, setMainSvg] = useState(null);
    const [viewAngle, setViewAngle] = useState("isometic");
    const navigate = useNavigate()
    //console.log("draggingTarget",draggingTarget)
    var mainCanvas = props.main.mainCanvas;
    var boothFileList = props.main.boothFileList;
    let query = useQuery();
    
    const init=()=> {
        if(mainCanvasRef.current){
            // const poly1 = [[[0,0],[2,0],[0,2],[0,0]]]
            // const poly2 = [[[-1,0],[1,0],[0,1],[-1,0]]]

            // const poly3 = polygonClipping.union       (poly1, poly2 /* , poly3, ... */)
            // console.log("poly3", poly3);

            g_mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
            g_mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
            reset();
            // camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 5000 );
            var aspect =  window.innerWidth/window.innerHeight
            var D = 150
            scene = new THREE.Scene()
            // camera = new THREE.OrthographicCamera(-D*aspect, D*aspect, D, -D, 1, 5000)            
            // camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 60, Math.max(g_mapWidth, g_mapHeight)*50 );
            camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, Math.max(g_mapWidth, g_mapHeight)*50 );
            camera.position.set( Math.max(g_mapWidth, g_mapHeight)/2, Math.max(g_mapWidth, g_mapHeight)/2, Math.max(g_mapWidth, g_mapHeight)/2 )
            camera.lookAt(scene.position)

            

            // scene.background = new THREE.Color( 0xa0a0a0 );
            // scene.fog = new THREE.Fog( 0xa0a0a0, 200, 3000 );
    
    
            //
    
            const hemiLight = new THREE.HemisphereLight( 0xa0a0a0, 0x444444 );
            hemiLight.position.set( 0, 200, 100 );
            scene.add( hemiLight );
    
            const directionalLight = new THREE.DirectionalLight( 0xf5f5ff );
            directionalLight.position.set( 0, 200, 100 );
            directionalLight.castShadow = true;
            // directionalLight.shadow.camera.top = 0;
            // directionalLight.shadow.camera.bottom = 0;
            // directionalLight.shadow.camera.left = 0;
            // directionalLight.shadow.camera.right = 0;
            scene.add( directionalLight );
    
            // ground
    
            const ground = new THREE.Mesh( new THREE.PlaneGeometry( g_mapWidth*1.5, g_mapHeight*1.5 ), new THREE.MeshPhongMaterial( { color: 0x999999, depthWrite: false } ) );
            ground.rotation.x = - Math.PI / 2;
            ground.receiveShadow = true;
            scene.add( ground );


            var convertRateX= 1
            var convertRateY= 1
            if(props.main.mainCanvas.convertRateX) {
                var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
                var convertRateY= Math.abs(props.main.mainCanvas.convertRateY)
            }
            var bgurl = null;
            var bg_width = 0;
            var bg_height = 0;
            if(props.main.bgImgCanvas.backgroundImage && props.main.bgImgCanvas.backgroundImage.src){
                bgurl = props.main.bgImgCanvas.backgroundImage.src
                // bg_width = props.main.bgImgCanvas.backgroundImage.width*props.main.bgImgCanvas.backgroundImage.scaleX
                // bg_height = props.main.bgImgCanvas.backgroundImage.height*props.main.bgImgCanvas.backgroundImage.scaleY
                bg_width = props.main.bgImgCanvas.backgroundImage.width
                bg_height = props.main.bgImgCanvas.backgroundImage.height
            }
            else if(props.main.bgImgCanvas.backgroundImage && props.main.bgImgCanvas.backgroundImage._element){
                bgurl = props.main.bgImgCanvas.backgroundImage._element.currentSrc
                bg_width = props.main.bgImgCanvas.backgroundImage.width
                bg_height = props.main.bgImgCanvas.backgroundImage.height
            }

            if(bgurl){
                var bg_loader = new THREE.TextureLoader();

                // Load an image file into a custom material
                var width = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
                var height = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
                var startX = 0-width/2 +props.main.bgImgCanvas.backgroundImage.left + bg_width/2-50;
                var startZ = 0-height/2 +props.main.bgImgCanvas.backgroundImage.top + bg_height/2-50;
                var material_bg = new THREE.MeshBasicMaterial({
                    map: bg_loader.load(bgurl.replace("http://12.41.127.199", "https://mapbeta.goeshow.com")), transparent: true
                });

                // combine our image geometry and material into a mesh
                var bg_mesh = new THREE.Mesh(new THREE.PlaneGeometry(bg_width, bg_height), material_bg);
                bg_mesh.position.set(startX,0,startZ)
                bg_mesh.rotation.x = - Math.PI / 2;

                bg_mesh.receiveShadow = false;
                scene.add(bg_mesh);                
            }
            var gridOffsetX = props.main.mainCanvas.gridOffsetX
            var gridOffsetY = props.main.mainCanvas.gridOffsetY
            var gridSize = props.main.mainCanvas.gridSize
            var unitRate = 12; //1feet = 12inch
            
        
            var mapWidth = props.main.mainCanvas.mapWidth*1
            var gridWidth;
            if(convertRateX){
                gridWidth = (gridSize*unitRate/convertRateX)
            }

        
            var realOffsetX = gridOffsetX?((gridOffsetX % gridWidth )-gridWidth):50
            var realOffsetY = gridOffsetY?((gridOffsetY % gridWidth )-gridWidth):50


            var map_width = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX)*2;
            var map_height = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY)*2;
            var boxSize = Math.max(map_width, map_height);
            var division = boxSize/gridWidth
            console.log("gridWidth", gridWidth);
            console.log("boxSize", boxSize);
            console.log("division", division);
            const grid = new THREE.GridHelper( boxSize, division, 0x000000, 0x000000 );
            grid.material.opacity = 0.1;
            grid.material.transparent = true;
            grid.position.x = -realOffsetX;
            grid.position.y = -3/convertRateX;
            grid.position.y = 0;
            grid.position.z = -realOffsetY;
            // grid.position.z = -20;

            scene.add( grid );
    
    
            const loader = new THREE.ObjectLoader();

            // loader.load(
            //     // resource URL
            //     `${assetsPath}box.json`,

            //     // onLoad callback
            //     // Here the loaded data is assumed to be an object
            //     function ( obj ) {
            //         // Add the loaded object to the scene
            //         scene.add( obj );
            //     },

            //     // onProgress callback
            //     // function ( xhr ) {
            //     //     console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            //     // },

            //     // // onError callback
            //     // function ( err ) {
            //     //     console.error( 'An error happened' );
            //     // }
            // );

            // const geometry = new THREE.BoxGeometry( 270, 0.5, 270 );
            // const material = new THREE.MeshPhongMaterial( { color: 0x817d7a } );
    
            // mesh = new THREE.Mesh( geometry, material );
            // mesh.castShadow = true;
            // mesh.position.x = 24;
            // mesh.position.y = 0;
            // mesh.position.z = 0;
            // scene.add( mesh );
    
            //
    
            renderer = new THREE.WebGLRenderer( { antialias: true } );
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( window.innerWidth-65, window.innerHeight );
            renderer.shadowMap.enabled = true;
            renderer.outputEncoding = THREE.sRGBEncoding;
            renderer.gammaOutput = true;
            renderer.gammaFactor = 2.2;
            while(mainCanvasRef.current.firstChild){
                mainCanvasRef.current.removeChild(mainCanvasRef.current.firstChild)
            }
            mainCanvasRef.current.appendChild( renderer.domElement );
    
            //
            
            controls = new MapControls( camera, renderer.domElement );
            controls.target.set( 0, 25, 0 );
            controls.enableZoom = true;
            // controls.enableRotate = false;
            controls.mouseButtons = {
                LEFT: THREE.MOUSE.PAN,
                // RIGHT: THREE.MOUSE.ROTATE,
                // MIDDLE: THREE.MOUSE.DOLLY,
            }

            controls.update();

            mainCanvasRef.current.addEventListener( 'wheel', handleMouseWheel, { passive: false } );
            //
            g_initDistance = controls.target.distanceTo( controls.object.position )
    
            // window.addEventListener( 'resize', onWindowResize );
        }
    }
    const navigate2D = ()=>{      
        if(props.main.mainCanvas){
            props.main.mainCanvas.dispose()
            props.setMainCanvas(null)
        }        
        props.setLoading(true)
        reset();
        setTimeout(function(){
            // delete props.main.mainCanvas; 
            if(query.get('map')){
                
                if(props.urlToken){
                    navigate(`${getTokenCookie()}?map=${query.get('map')?query.get('map'):''}${query.get('sales_map')?"&sales_map=1":""}`)
                }
                else
                    navigate(`/?map=${query.get('map')?query.get('map'):''}&show_key=${query.get('show_key')?query.get('show_key'):''}${query.get('sales_map')?"&sales_map=1":""}`)
            }
            else{
                navigate(`/admin?show_key=${query.get('show_key')?query.get('show_key'):''}`)
            }
        }, 200)
    }
    const reset=()=>{
        if(scene){    
            if(meshes && meshes.length > 0){
                for(var j = 0; j< meshes.length; j++){
                    if(meshes[j].geometry)
                        meshes[j].geometry.dispose();
                    if(meshes[j].material)
                        meshes[j].material.dispose();
                    scene.remove(meshes[j])
                }
            }        
            meshes = [];
            for(var i in scene){
                delete scene[i]
            }
            scene = null;
        }
        if(camera){
            for(var i in camera){
                delete camera[i]
            }
            camera = null;
        }
        if(animateId){
            cancelAnimationFrame(animateId)
            animateId = null
        }
        if(renderer){
            for(var i in renderer){
                delete renderer[i]
            }
            renderer = null;
        }
    }
    const animate =()=> {
        animateId = requestAnimationFrame( animate );
		const dt = clock.getDelta();
        movePlayer(dt)
        if(g_rotateLeft){
            rotateLeft()
        }
        if(g_rotateRight){
            rotateRight()
        }
        if(renderer)
            renderer.render( scene, camera );
    }
    var temInd = 0;
    const loadHuman=()=>{
        const loader = new FBXLoader();
        for(var humanInd = 0; humanInd < 3; humanInd++){
            loader.load( `${assetsPath}man.fbx`, function ( object ) {

                object.mixer = new THREE.AnimationMixer( object );
                
                object.scale.set(0.15, 0.15, 0.15)
                
                object.name = "human";
                        
                object.traverse( function ( child ) {
                    if ( child.isMesh ) {
                        child.castShadow = true;
                        child.receiveShadow = false;		
                    }
                } );                
                    var player = {};
                    var object_tmp = object
                    player.mixer = object_tmp.mixer;
                    player.root = object_tmp.mixer.getRoot();
                    player.object = new THREE.Object3D();
                    player.object.position.x = temInd*100;
                    player.object.position.y = 0;
                    player.object.position.z = temInd*50;
                    scene.add(player.object);
                    player.object.add(object_tmp);
                    player.object.rotateY(90*temInd);
                    player.move = { forward:1, turn:0 }; 
                    players.push(player);
                    temInd++;
                // animations.Idle = object.animations[0];
            } );
        }
    }
    const movePlayer = (dt)=>{
        if(players.length < 0) return;
        for(var playerInd = 0; playerInd < players.length; playerInd++){
            if(!players[playerInd].object){
                return
            }
            
            const pos = players[playerInd].object.position.clone();
            pos.y += 60;
            let dir = new THREE.Vector3();
            players[playerInd].object.getWorldDirection(dir);
            if (players[playerInd].move.forward<0) dir.negate();
            let raycaster = new THREE.Raycaster(pos, dir);
            let blocked = false;
        
            if (colliders!==undefined){ 
                const intersect = raycaster.intersectObjects(colliders);
                if (intersect.length>0){
                    if (intersect[0].distance<50) blocked = true;
                }
            }
            
            if (!blocked){
                if (players[playerInd].move.forward>0){
                    const speed = (players[playerInd].action=='Running') ? 400 : 50;
                    players[playerInd].object.translateZ(dt*speed);
                }else{
                    players[playerInd].object.translateZ(-dt*30);
                }
            }
            
            if (colliders!==undefined){
                //cast left
                dir.set(-1,0,0);
                dir.applyMatrix4(players[playerInd].object.matrix);
                dir.normalize();
                raycaster = new THREE.Raycaster(pos, dir);

                let intersect = raycaster.intersectObjects(colliders);
                if (intersect.length>0){
                    if (intersect[0].distance<50) players[playerInd].object.translateX(100-intersect[0].distance);
                }
                
                //cast right
                dir.set(1,0,0);
                dir.applyMatrix4(players[playerInd].object.matrix);
                dir.normalize();
                raycaster = new THREE.Raycaster(pos, dir);

                intersect = raycaster.intersectObjects(colliders);
                if (intersect.length>0){
                    if (intersect[0].distance<50) players[playerInd].object.translateX(intersect[0].distance-100);
                }
                
                //cast down
                dir.set(0,-1,0);
                pos.y += 200;
                raycaster = new THREE.Raycaster(pos, dir);
                const gravity = 30;

                intersect = raycaster.intersectObjects(colliders);
                if (intersect.length>0){
                    const targetY = pos.y - intersect[0].distance;
                    if (targetY > players[playerInd].object.position.y){
                        //Going up
                        players[playerInd].object.position.y = 0.8 * players[playerInd].object.position.y + 0.2 * targetY;
                        players[playerInd].velocityY = 0;
                    }else if (targetY < players[playerInd].object.position.y){
                        //Falling
                        if (players[playerInd].velocityY==undefined) players[playerInd].velocityY = 0;
                        players[playerInd].velocityY += dt * gravity;
                        players[playerInd].object.position.y -= players[playerInd].velocityY;
                        if (players[playerInd].object.position.y < targetY){
                            players[playerInd].velocityY = 0;
                            players[playerInd].object.position.y = targetY;
                        }
                    }
                }else if (players[playerInd].object.position.y>0){
                    if (players[playerInd].velocityY==undefined) players[playerInd].velocityY = 0;
                    players[playerInd].velocityY += dt * gravity;
                    players[playerInd].object.position.y -= players[playerInd].velocityY;
                    if (players[playerInd].object.position.y < 0){
                        players[playerInd].velocityY = 0;
                        players[playerInd].object.position.y = 0;
                    }
                }
            }
            
            players[playerInd].object.rotateY(players[playerInd].move.turn*dt);
        }
	}
    var map_width = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
    var map_height = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
    var widthScale = 1;
    var heightScale = 1;
    if(map_width > 0){
        widthScale = (window.innerWidth-100)/map_width/1.2;
    }
    if(map_height > 0){
        heightScale =( window.innerHeight-200)/map_height/1.2;
    }
    console.log("widthScale", widthScale)
    console.log("heightScale", heightScale)
    const minZoom = 0.5; //0.01
    const maxZoom = 100
    function dollyOut(controls) {
        var distance = controls.target.distanceTo( controls.object.position )
        var zoom = g_initDistance/distance
        console.log("zoom", zoom)
        console.log("g_initDistance", distance)
        console.log("minZoom", minZoom)
        console.log("rate", g_initDistance/zoom)
        if(zoom< minZoom)
            return;
        controls.setZoom(1/0.9);

    }

    function dollyIn(controls) {
        controls.setZoom(0.9);
    }

    function handleMouseWheel( event ) {
        // function handleMouseWheel( event ) {

            // if ( event.deltaY < 0 ) {
    
            //     dollyIn(camera);
    
            // } else if ( event.deltaY > 0 ) {
    
            //     dollyOut(camera);
    
            // }
    
        // }
    }

    function rotateLeft() {

        // var z = camera.position.z;
        // var x = camera.position.x;
        // camera.position.x = x * Math.cos(0.1/camera.zoom) - z * Math.sin(0.1/camera.zoom);
        // camera.position.z = z * Math.cos(0.1/camera.zoom) + x * Math.sin(0.1/camera.zoom);
        // camera.lookAt(scene.position);
        // camera.updateProjectionMatrix();

        // z = controls.target.z;
        // x = controls.target.x;
        // var newx = x * Math.cos(0.1/camera.zoom) - z * Math.sin(0.1/camera.zoom);
        // var newz = z * Math.cos(0.1/camera.zoom) + x * Math.sin(0.1/camera.zoom);

        // controls.target.set( newx, 25, newz);
        

        // controls.update();
        controls.rotateLeft();
        controls.update();

    }

    
    function rotateRight() {
        console.log("camera.position", camera.position)
        console.log("controls.target", controls.target)
        // var z = camera.position.z;
        // var x = camera.position.x;
        // camera.position.x = x * Math.cos(0.1) + z * Math.sin(0.1);
        // camera.position.z = z * Math.cos(0.1) - x * Math.sin(0.1);
        // camera.lookAt(scene.position);
        // camera.updateProjectionMatrix();

        // z = controls.target.z;
        // x = controls.target.x;
        // var newx = x * Math.cos(0.1) + z * Math.sin(0.1);
        // var newz = z * Math.cos(0.1) - x * Math.sin(0.1);

        // controls.target.set( newx, 25, newz);
        // controls.update();
        controls.rotateRight();
        controls.update();
    }
    

    function swichFullView(){
          controls.reset();
        controls.setZoom(minZoom?1/minZoom:1);
    }

    function swichViewAngle(event){
        if(viewAngle == "isometic"){
            setViewAngle('top')
            camera.position.set( 0, Math.max(g_mapWidth, g_mapHeight)/2, 0 )
            controls.target.set( 0, 0, 0 );
            controls.update();
            controls.saveState();
            g_initDistance = controls.target.distanceTo( controls.object.position )
        }
        else{
            setViewAngle('isometic')
            camera.position.set( Math.max(g_mapWidth, g_mapHeight)/2, Math.max(g_mapWidth, g_mapHeight)/2, Math.max(g_mapWidth, g_mapHeight)/2 )
            controls.target.set( 0, 0, 0 );
            controls.update();
            controls.saveState();
            g_initDistance = controls.target.distanceTo( controls.object.position )
        }
        camera.lookAt(scene.position)
    }

    function downloadJson(json) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(json));
        element.setAttribute('download', 'room.json');
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
    }
      
    const link = document.createElement( 'a' );
    link.style.display = 'none';
    document.body.appendChild( link ); // Firefox workaround, see #6594

    function save( blob, filename ) {

        link.href = URL.createObjectURL( blob );
        link.download = filename;
        link.click();

        // URL.revokeObjectURL( url ); breaks Firefox...

    }

    function saveString( text, filename ) {

        save( new Blob( [ text ], { type: 'text/plain' } ), filename );

    }
    function saveArrayBuffer( buffer, filename ) {

        save( new Blob( [ buffer ], { type: 'application/octet-stream' } ), filename );

    }
    const params = {
        trs: false,
        onlyVisible: true,
        truncateDrawRange: true,
        binary: false,
        maxTextureSize: 4096,
    };

    function exportGLTF( input ) {

        const gltfExporter = new GLTFExporter();

        const options = {
            trs: params.trs,
            onlyVisible: params.onlyVisible,
            truncateDrawRange: params.truncateDrawRange,
            binary: params.binary,
            maxTextureSize: params.maxTextureSize
        };
        gltfExporter.parse(
            input,
            function ( result ) {

                if ( result instanceof ArrayBuffer ) {

                    saveArrayBuffer( result, 'scene.glb' );

                } else {

                    const output = JSON.stringify( result, null, 2 );
                    //console.log( output );
                    saveString( output, 'scene.gltf' );

                }

            },
            function ( error ) {

                //console.log( 'An error happened during parsing', error );

            },
            options
        );

    }

    useEffect(async ()=>{        
        props.setLoading(true)
        if(mainCanvasRef.current){
            // mainCanvasRef.current.className = 'hidden';
        }
        init();
        animate();
        // if(boothFileList && boothFileList.length>0){
        //     loadBooth()
        // }
        if(0){
            const loader = new GLTFLoader();
            boothModel = await loader.loadAsync( `${assetsPath}booth/scene.gltf`);
            boothModel.scene.scale.set(0.05, 0.05, 0.05)
            boothModel.scene.position.x = 0;
            boothModel.scene.position.y = 0;
            boothModel.scene.position.z = 0;
            // scene.add(boothModel.scene)
            exportGLTF(boothModel.scene)
            downloadJson(JSON.stringify(boothModel.scene.toJSON()))
        }        
        if(0) { //fbx -> gltf
            const loader = new FBXLoader();
            boothModel = await loader.loadAsync( `${assetsPath}table_model/round_table_9/scene.fbx`);
            // boothModel.scene.scale.set(0.05, 0.05, 0.05)
            // boothModel.scene.position.x = 0;
            // boothModel.scene.position.y = 0;
            // boothModel.scene.position.z = 0;
            // scene.add(boothModel.scene)
            exportGLTF(boothModel)
        }
        const fontLoader = new FontLoader();
        fontLoader.load(
            `${assetsPath}fonts/helvetiker_regular.typeface.json`,
            async function ( ft ) {
                // do something with the font
                font = ft
                console.log( font );
                // const loader = new THREE.ObjectLoader();
                // boothModel = await loader.loadAsync( `${assetsPath}booth_json/booth1.json`);
                // boothModel.scale.set(0.04, 0.04, 0.04)
                // boothModel.position.x = 0;
                // boothModel.position.y = 0;
                // boothModel.position.z = 0;
                // scene.add(boothModel)

                const gltfLoader = new GLTFLoader();
                // const dracoLoader = new DRACOLoader();
                // dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
                // dracoLoader.setDecoderPath( '../../node_modules/three/examples/js/libs/draco/gltf/' );
                // dracoLoader.setDecoderConfig({ type: 'js' });
                gltfLoader.setMeshoptDecoder( MeshoptDecoder );

                var model = await gltfLoader.loadAsync( `${assetsPath}table_model/BANQUET_TABLE/9X9/t6.gltf`);
                table6Model = model.scene
                // table10Model.scene.traverse(function (child) {
                //     if ((child).isMesh) {
                //         const m = child
                //         m.receiveShadow = true
                //         m.castShadow = true
                //     }
                //     if ((child ).isLight) {
                //         const l = child
                //         l.castShadow = true
                //         l.shadow.bias = -0.003
                //         l.shadow.mapSize.width = 2048
                //         l.shadow.mapSize.height = 2048
                //     }
                // })
                table6Model.traverse(function (child) {
                    if ((child).isMesh) {
                        const m = child
                        m.receiveShadow = true
                        m.castShadow = true
                        // m.material.color = new THREE.Color(0xffffff)
                        m.material.metalness = 0
                        m.position.set( 0, 0, 0 );
                    }
                    if ((child ).isLight) {
                        const l = child
                        l.castShadow = true
                        l.shadow.bias = -0.003
                        l.shadow.mapSize.width = 2048
                        l.shadow.mapSize.height = 2048
                    }
                })

                model = await gltfLoader.loadAsync( `${assetsPath}table_model/BANQUET_TABLE/10X10/t8.gltf`);
                table8Model = model.scene
                table8Model.traverse(function (child) {
                    if ((child).isMesh) {
                        const m = child
                        m.receiveShadow = true
                        m.castShadow = true
                        // m.material.color = new THREE.Color(0xffffff)
                        m.material.metalness = 0
                        m.position.set( 0, 0, 0 );
                    }
                    if ((child ).isLight) {
                        const l = child
                        l.castShadow = true
                        l.shadow.bias = -0.003
                        l.shadow.mapSize.width = 2048
                        l.shadow.mapSize.height = 2048
                    }
                })
                // table8Model.scale.set(0.4, 0.4, 0.4)
                // table8Model.scene.position.x = 0;
                // table8Model.scene.position.y = 0;
                // table8Model.scene.position.z = 100;
                // scene.add(table8Model.scene)

                model = await gltfLoader.loadAsync( `${assetsPath}table_model/BANQUET_TABLE/11X11/t8.gltf`);
                table10Model = model.scene
                // table10Model.scene.traverse(function (child) {
                //     if ((child).isMesh) {
                //         const m = child
                //         m.receiveShadow = true
                //         m.castShadow = true
                //     }
                //     if ((child ).isLight) {
                //         const l = child
                //         l.castShadow = true
                //         l.shadow.bias = -0.003
                //         l.shadow.mapSize.width = 2048
                //         l.shadow.mapSize.height = 2048
                //     }
                // })
                 table10Model.traverse(function (child) {
                    if ((child).isMesh) {
                        const m = child
                        m.receiveShadow = true
                        m.castShadow = true
                        // m.material.color = new THREE.Color(0xffffff)
                        m.material.metalness = 0
                        m.position.set( 0, 0, 0 );
                    }
                    if ((child ).isLight) {
                        const l = child
                        l.castShadow = true
                        l.shadow.bias = -0.003
                        l.shadow.mapSize.width = 2048
                        l.shadow.mapSize.height = 2048
                    }
                })

                // mainCanvasRef.current.className = 'visible';
                var content = props.booth_history.content
                if(content){
                    //console.log("content", content)
                    await renderBooth(scene, camera, content, props, boothModel, font, meshes, table6Model, table8Model, table10Model)      
                    markActiveBooth(camera, controls,meshes,  props.main.active3dObject, viewAngle, g_mapWidth, g_mapHeight);
                    //console.log("render done")   
                }    
                props.setLoading(false)
            },
        
            // onProgress callback
            function ( xhr ) {
                //console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
        
            function ( err ) {
                //console.log( 'An error happened' );
            }
        );        
            
    }, [])

    useEffect(()=>{
        if(camera && controls){    
            markActiveBooth(camera, controls, meshes,  props.main.active3dObject, viewAngle, g_mapWidth, g_mapHeight);
        }
    }, [props.main.active3dObject])
    
    useEffect(()=>{
        if(props.main.to2dFlag){    
            navigate2D() 
        }
    }, [props.main.to2dFlag])

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);
    
    const loadBooth=()=>{
        var activeFile;
        for(var i = 0; i <boothFileList.length; i++){
            if(boothFileList[i]['active']){
                activeFile = boothFileList[i];
                break;
            }
        }
        if(activeFile){
            props.setLoading(true)
            axios.get(`/getboothfile?id=${activeFile['id']}`).then(res=>{
                props.setLoading(false)
                //console.log(res.data)
                var content = res.data.content;
                if(content){
                    content  = JSON.parse(content);
                    //console.log("content", content)
                    renderBooth(scene, content, props, boothModel, font, meshes, table8Model, table10Model)
                   
                    // loadHuman();
                }
            }).catch(error => {
                //console.log("error", error);
                props.setLoading(false)
            })
        }
    }


   

    return (
        <>
            {/* <ToolBox {...props}
                dollyIn = {dollyIn}
                dollyOut = {dollyOut}
                swichViewAngle = {swichViewAngle}
                swichFullView = {swichFullView}
            /> */}
            {/* <div className="controls -ready">
                <Button title="Zoom In" onClick={()=>{dollyIn()}}><ZoomInIcon/></Button>
                <Button style={{ marginTop: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut()}}><ZoomOutIcon/></Button>
                <Button style={{ marginTop: '5px' }}  title="Toggle View" onClick={()=>{swichViewAngle()}}><CameraswitchIcon/></Button>
                <Button style={{ marginTop: '5px' }}  title="Auto Fit on Screen" onClick={()=>{swichFullView()}}><FullscreenIcon/></Button>
                
            </div> */}
            <Box className="main-container"  sx={{ flexGrow: 1}}>
                {/* <BoothList/> */}
                <div ref={mainCanvasRef}>

                </div>
                <div className="canvas-viewer">
                    <div>
                        {/* <div style ={{paddingLeft:50,background: '#ededed'}}>
                            <canvas id="top-ruler" width={`${maincanvas_width}px`} height="50"/>
                        </div> */}
                        <div className="controls -ready client-control">
                            {/* <Button style={{ marginTop: '5px' }}  title="Booth List" onClick={()=>{}}><ListIcon/></Button>
                            <Button style={{ marginTop: '5px' }} type="button" title="Legend" onClick={()=>{}}><PriorityHighIcon/></Button> */}
                            <Button style={{ marginTop: '5px' }}  title="Auto Fit on Screen" onClick={()=>{swichFullView()}}><FullscreenIcon/></Button>
                            <Button style={{ marginTop: '5px' }}  title="Toggle View" onClick={()=>{swichViewAngle()}}><CameraswitchIcon/></Button>
                            <Button style={{ marginTop: '5px' }} title="Zoom In" onClick={()=>{dollyIn(controls)}}><ZoomInIcon/></Button>
                            <Button style={{ marginTop: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut(controls)}}><ZoomOutIcon/></Button>
                            <Button style={{ marginTop: '5px' }}  title="2D" 
                            
                                onClick={()=>{ 
                                    if(props.urlToken){
                                        navigate(`${getTokenCookie()}?map=${query.get('map')?query.get('map'):''}${query.get('sales_map')?"&sales_map=1":""}`)
                                    }
                                    else
                                        navigate(`/?map=${query.get('map')?query.get('map'):''}&show_key=${query.get('show_key')?query.get('show_key'):''}${query.get('sales_map')?"&sales_map=1":""}`); 
                                }}
                            ><strong>2D</strong></Button>
                        </div>
                        <div className="controls three-nav">
                            <Button style={{ marginTop: '5px', marginRight: 15 }}  title="Rotate Left" 
                                onMouseDown={
                                    ()=>{
                                        g_rotateLeft = true
                                        // if(!interval)
                                        //     interval = setInterval(() => {
                                        //         rotateLeft()
                                        //     }, 5);
                                    }
                                } 
                                onMouseUp={
                                    ()=>{
                                        g_rotateLeft = false
                                        // clearInterval(interval)
                                        // interval = null
                                    }
                                }
                                onMouseOut={
                                    ()=>{
                                        g_rotateLeft = false
                                        // clearInterval(interval)
                                        // interval = null
                                    }
                                }
                                // onClick={()=>{rotateLeft()}}
                            >
                                <WestIcon/>
                            </Button>
                            <Button style={{ marginTop: '5px' }}  title="Rotate Right" 
                                onMouseDown={
                                    ()=>{
                                        g_rotateRight = true
                                        // if(!interval)
                                        //     interval = setInterval(() => {
                                        //         rotateRight()
                                        //     }, 5);
                                    }
                                } 
                                onMouseUp={
                                    ()=>{
                                        g_rotateRight = false
                                        // clearInterval(interval)
                                        // interval = null
                                    }
                                }
                                onMouseOut={
                                    ()=>{
                                        g_rotateRight = false
                                        // clearInterval(interval)
                                        // interval = null
                                    }
                                }
                                // onClick={()=>{rotateRight()}}
                            >
                                <EastIcon/>
                            </Button>
                        </div>
                    </div>
                </div>
            </Box>

            {/* <AppLeftPanel/> */}
            <LoadingOverlay
                active={props.main.loadingFlag}
                styles={{
                    // spinner: (base) => ({
                    // ...base,
                    // width: '50px',
                    // '& svg circle': {
                    //     stroke: '#1976d2'
                    // }
                    // }),
                    overlay: (base) => ({
                    ...base,
                    fontSize:'18px',
                    color: 'rgb(5, 37, 51)',
                    // background: 'rgb(229 229 229 / 92%)',
                    background: 'transparent',
                    position:'fixed',
                    zIndex:1000000000
                    })
                }}
                spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
                // spinner
                // text='Loading ...'
                >
            </LoadingOverlay> 
        </>
    );
  }
  
  
const mapStateToProps = (state) => {
    return {
      main: state.main,
      booth_history: state.booth_history.present
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setMainCanvas: canvas=>dispatch(setMainCanvas(canvas)),
        setMainLayers: layers=>dispatch(setMainLayers(layers)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)) ,
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),  
        setLoading:  data=>dispatch(setLoading(data)),
        to2D:  data=>dispatch(to2D(data)),
        setActive3dObject
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(MainViewer);

//wall


  