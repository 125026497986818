import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox';
import AppLeftPanel from './viewer/component/AppLeftPanel';
import { setAuthInfo, setLoading, setBookmarkList, setExhibitorList} from './actions/main'
import { setOpenCompanyProfile } from './actions/ui'
import { setCustomStyles } from './actions/ui'
import { setOpenLogin } from './actions/ui'
import { parseToken, getTokenCookie, getTokenPlanner, setCookie } from './utils/Common'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import LeftProfile from  './viewer/component/LeftProfile';
import ExhibitorCard from  './viewer/component/ExhibitorCard';

import { useParams } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import qs, { parse } from 'qs'
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { API_URL, ESHOW_URL } from './config.js'
import axiosInstance from './utils/axiosInstance.js'
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(1);
  const [keySelect, setKeySelect] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const [dataFetched, setDataFetched] = useState(false);
  const [boothFileList, setBoothFileList] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [pulldownValue, setPulldownValue] = useState(false);
  const page_size = 16;
  const keywordInput = useRef(null);
  const selectRef = useRef(null);
  
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();

  const processAuth = async ()=>{      
    var tokenInfo = await parseToken(query)  
    await fetchCustomStyles();          
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, SALES,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        return
    }
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url = `${ESHOW_URL()}?method=getCSS&token=${getTokenCookie()}`
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getCSS&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    const response = await axiosInstance.get(url)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const fetchData = ()=>{
    props.setLoading(true)
    var token = getTokenCookie();   
    try{     
        var _token = getTokenPlanner();
        if(_token){
          token = _token
        }
    }
    catch(e){
      console.log("token parse", e)
    }
    var url = `${ESHOW_URL()}?method=getExhibitorList&token=${token}`
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }

    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){
          props.setExhibitorList(result)  
          if(result.EXHIBITORS){
            var boothList = [];
            for(var i = 0; i< result.EXHIBITORS.length; i++){
              var item = result.EXHIBITORS[i]
              if(!item?.SETUP?.LIST_PENDING_EXHIBITORS && item.STATUS == 2)
                continue;
              boothList.push(item)
            }        
            setData(boothList)
            setFilteredItems(boothList)
          }
        }
        else{
          setData([])
          setFilteredItems([])
        }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    // if(props.urlToken){
    //     var public_token = `/${id1}/${id2}/${id3}`
    //     setCookie('V3PUBLICMAP', public_token+(id4?`/${id4}`:''))
    // }
    // processAuth()
    props.setOpenCompanyProfile(false)
  }, [])

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var show_key = urlParams.get('show_key')     
  useEffect(() => {
    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    fetchSearchOptions()
    fetchBookmarkList()
    fetchData()
    fetchboothlist()
  }, [props.main[`planner-${show_key}`], id5, id4, id3, id2, id1])

  
  useEffect(() => {
    if(data.length)
      doSearch()
  }, [data])

  
  useEffect(() => {
    if(data.length)
      doSearch()
  }, [keySelect, pulldownValue])
  

  const openCompanyProfile=(exhibitor)=>{   
    var booth = {};
    booth.exhibitors = [exhibitor]
    booth.booth_number = exhibitor?.BOOTHS

    props.setOpenCompanyProfile({booth:booth, open:true})
  }
  const resetSearch=()=>{
    if(keywordInput?.current)
      keywordInput.current.value = ''
    if(selectRef?.current)
      selectRef.current.value = ''
    setFilteredItems(data)
  }
  const doSearch=(value)=>{
    var keyword = null
    let searchOption = getSearchOption(keySelect)
    if(value){
      keyword = value
    }
    else{
      if(searchOption && searchOption.TYPE == 'pulldown'){
        keyword = pulldownValue
      }
      else{
        keyword = keywordInput?.current?.value
      }
    }
    if(!keyword) keyword = ''
    keyword = `${keyword}`
    var filteredItems = data;
    if(keyword == "" || keyword == '""' || !keyword){
      setFilteredItems(filteredItems)
      return;
    }
    if(keyword && keyword.trim() !== '') {
      filteredItems = data.filter((item)=>{
        var str = ''
        if(keySelect == "Company Description"){
          str = `${item.DESCRIPTION?item.DESCRIPTION:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Exhibitor Name"){
          str = `${item.COMPANY_NAME?item.COMPANY_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Product Categories"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }          
        else if(keySelect == "Booth Number"){
          str = `${item.BOOTHS?item.BOOTHS:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Map Name"){
          str = `${item.MAP_NAME?item.MAP_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "States"){
          str = `${item.STATE?item.STATE:''}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == "Countries"){
          str = `${item.COUNTRY?item.COUNTRY:'us'}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == 'Featured Exhibitors'){
          str = `${item.FEATURED?item.FEATURED:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Region of Services"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }     
        else if(keySelect == "Service Specialty"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }             
        else{
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }
      })      
    }
    setFilteredItems(filteredItems)
  }
  const getSearchOption = (value)=>{
    console.log("searchOptions", searchOptions)
    if (!value) return false;
    for(var i = 0; i < searchOptions?.length; i++){
      if(searchOptions[i].LABEL == value){
        return searchOptions[i]
      }
    }
    return false
  }

  const searchBox = ()=>{
    let searchOption = getSearchOption(keySelect)
    let dropdownOptions = [];
    if(searchOption && searchOption.TYPE == 'pulldown'){
      if(searchOption.ID == 'question'){
        for(var i = 0; i < searchOption.ANSWERS?.length; i++){
          let title = searchOption.ANSWERS[i].answer_title
          if(title === true){
            title = "Yes"
          }
          if(title === false){
            title = "No"
          }
          dropdownOptions.push(
            {
              value:`${searchOption.ANSWERS[i].answer_key}`?.trim(),
              label:`${title}`?.trim()+(searchOption.ANSWERS[i].qty?(` (${searchOption.ANSWERS[i].qty})`):""),
            }
          )
        }
      }
      else if(searchOption.ID == 'map_name'){
        for(var i = 0; i < searchOption.MAPS?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.MAPS[i].TITLE}`?.trim(),
              label:`${searchOption.MAPS[i].TITLE}`?.trim(),
            }
          )
        }
      }
      else if(searchOption.ID == 'state'){
        for(var i = 0; i < searchOption.STATES?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.STATES[i].VALUE}`?.trim(),
              label:`${searchOption.STATES[i].TITLE}`?.trim()+(searchOption.STATES[i].QTY?(` (${searchOption.STATES[i].QTY})`):""),
            }
          )
        }
      }
      else if(searchOption.ID == 'country'){
        for(var i = 0; i < searchOption.COUNTRIES?.length; i++){
          dropdownOptions.push(
            {
              value:`${searchOption.COUNTRIES[i].VALUE}`?.trim(),
              label:`${searchOption.COUNTRIES[i].TITLE}`?.trim()+(searchOption.COUNTRIES[i].QTY?(` (${searchOption.COUNTRIES[i].QTY})`):""),
            }
          )
        }
      }
    }
    return (
      <Paper
        className='search-bar'
        component="form"
        sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', maxWidth: '750px' }}
      >
        <FormControl variant="outlined" style={{width:'170px', border:'none'}}>
          <Select
            className='key-select'
            style={{border:'none', outline:'none'}}
            // displayEmpty
            value={keySelect?keySelect:'Keyword'}
            onChange={(e)=>{
              setKeySelect(e.target.value); setPulldownValue(false);
              if(keywordInput?.current)
                keywordInput.current.value = ''
              if(selectRef?.current)
                selectRef.current.value = ''
            }}
          >
            {
              searchOptions.map((searchOption, index)=>{
                return(
                <MenuItem value={searchOption.LABEL} key={index}>{searchOption.LABEL}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        
        <Divider sx={{ height: '32px', m: 0.5 }} orientation="vertical" />
        
        {searchOption.TYPE == 'pulldown' ?
          <Select
            className='key-select'
            sx={{ flex: 1, width:300,border:'none', outline:'none', textAlign:'left'  }}
            // displayEmpty
            ref={selectRef}
            value = {pulldownValue}
            onChange={(e)=>{setPulldownValue(e.target.value)}}
          >
            {
              dropdownOptions.map((dropdownOption, index)=>{
                return(
                  <MenuItem value={dropdownOption.value} key={index}>{dropdownOption.label}</MenuItem>
                )
              })
            }
          </Select>:
          <InputBase
            sx={{ flex: 1, width:300  }}
            placeholder="Search by keyword"
            inputProps={{ 'aria-label': 'Search by keyword' }}
            onKeyDown={
              (e)=>{
                  if(e.keyCode == 13){
                      e.preventDefault();
                      doSearch()
                  }
              }
            }
            inputRef={keywordInput}
          />
        }
        {searchOption.TYPE != 'pulldown' &&
        <IconButton aria-label="delete" onClick={resetSearch} style={{padding:0, marginRight:5}}>
          <HighlightOffIcon  sx={{ p: '5px' }} />
        </IconButton>
        }
        {searchOption.TYPE != 'pulldown' &&
        <Button onClick={(()=>doSearch())} className={`${classes.searchBtn} search-attach` } variant="contained" color="primary" sx={{ p: '10px' }} aria-label="directions">
          <SearchIcon sx={{ p: '5px' }} />
          Search
        </Button>
        }
      </Paper>
    )
  }

  const fetchBookmarkList = ()=>{
  { 
      try{
        var token =  getTokenPlanner();
        if(token){
          var url  = `${ESHOW_URL()}?method=getPlanner&token=${token}`
          var formdata = 
          {  
              query:`${ESHOW_URL()}?method=getPlanner&token=${token}`                
          }
          var data = qs.stringify({
              request: JSON.stringify(formdata)
          });
          const options = {
              headers: {"content-type": 'application/x-www-form-urlencoded'}
          }
          axiosInstance.get(url).then(res=>{
              const result = res.data;
              if(result.SUCCESS){
                props.setBookmarkList(result.BOOKMARKS)
              }
              else{

              }
          }).catch(error => {
          })  
        }
      }
      catch(e){
        console.log(e)
      }
    }
  }

  const fetchSearchOptions = ()=>{
    { 
        try{
          var token = getTokenCookie();   
          if(token){
            var url  = `${ESHOW_URL()}?method=getSearchOptions&token=${token}`
            var formdata = 
            {  
                query:`${ESHOW_URL()}?method=getSearchOptions&token=${token}`                
            }
            axiosInstance.get(url).then(res=>{
                const result = res.data;
                if(result.SUCCESS){
                  setSearchOptions(result.SEARCH)
                  if(result.SEARCH && result.SEARCH?.length){
                    setKeySelect(result.SEARCH[0].LABEL)
                  }
                  console.log("searchOptions", result.SEARCH)
                }
                else{
  
                }
            }).catch(error => {
            })  
          }
        }
        catch(e){
          console.log(e)
        }
      }
    }

  const callBookmarkApi= async (exhibitor, value)=>{
    const _token = getTokenPlanner();
    var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
    if(value){
      BOOKMARKS = [
        ...BOOKMARKS,
        {
          COMPANY_NAME: exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:"",
          NOTES:"",
          STATUS:'',
          BOOTHS:exhibitor.BOOTHS,
          EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
        }
      ]
    }
    else{
      BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
    }
    props.setBookmarkList(BOOKMARKS)
    var postData =
    {
      SUCCESS:true,
      BOOKMARKS:BOOKMARKS
    };

    var formdata = {
      query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS == true){
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to save bookmark");
      }
      // props.setViewFile(filename);
    }).catch(error => {
        console.log("error", error);
    })
  }

  const fetchboothlist=async (save_id=false)=>{   
    var url = `${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`;
    var formdata = 
    {  
        query:`${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`                
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
      setBoothFileList(res.data)
    }).catch(error => {
    })      
  }

  const doBookmark = (exhibitor)=>{
    if(exhibitor){
      try{
        const _token = getTokenPlanner();
        if((props.role !='admin') && (!_token || !_token)){
          props.setOpenLogin(true)
          return false;
        }
        var tmp = [...data];
        for(var i = 0; i < tmp.length; i++){
          if(tmp[i].EXHIBITOR_KEY == exhibitor.EXHIBITOR_KEY){
            tmp[i].BOOKMARKED = !tmp[i].BOOKMARKED
            callBookmarkApi(exhibitor, tmp[i].BOOKMARKED)
          }
        }
        this.setData(tmp);
        // var tmp1 = [...filteredItems];
        // for(var i = 0; i < tmp1.length; i++){
        //   if(tmp1[i].EXHIBITOR_KEY == exhibitor.EXHIBITOR_KEY){
        //     tmp1[i].BOOKMARKED = !tmp1[i].BOOKMARKED
        //     callBookmarkApi(exhibitor, tmp1[i].BOOKMARKED)
        //   }
        // }
        // this.setFilteredItems(tmp1)
      }
      catch(e){

      }
    }
  }

  console.log("render client app")
  console.log("data", filteredItems);
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  var sales_bookmarks_show = true
  if(props.main.authInfo && props.main.authInfo.SALES){
    if(props.main.authInfo.SALES.BOOKMARKS == 'hide'){
      sales_bookmarks_show = false
    }
  }

  var outputItems = [];
  let items = [
    {
      value: 1,
      FEATURED: false
    },
    {
      value: 2,
      FEATURED: false
    },
    {
      value: 3,
      FEATURED: true
    },
    {
      value: 4,
      FEATURED: false
    },
    {
      value: 5,
      FEATURED: true
    }
  ]

  
  items.sort(function (a, b){
    if(a.FEATURED === true && (!b.FEATURED || b.FEATURED == "")){
      return -1
    }
    else if(b.FEATURED === true && (!a.FEATURED || a.FEATURED == "")){
      return 1
    }
    else {
      return 0;
    }
   
  })

  console.log("items", items)
  outputItems = filteredItems.sort(function (a, b){
    if(a.FEATURED === true && (!b.FEATURED || b.FEATURED == "")){
      return -1
    }
    else if(b.FEATURED === true && (!a.FEATURED || a.FEATURED == "")){
      return 1
    }
    else {
      if(keySelect == 'Company Description')
        return `${a.DESCRIPTION}`.localeCompare(`${b.DESCRIPTION}`);
      else if(keySelect == 'Exhibitor Name')
        return `${a.COMPANY_NAME}`.localeCompare(`${b.COMPANY_NAME}`);
      else if(keySelect == 'Product Categories'||keySelect == 'Region of Services'||keySelect == 'Service Specialty'){
          var str_a = ""
          var str_b = ""
          var categories_a = a.CATEGORY;
          for(var i = 0; i <categories_a?.length; i++){
            str_a+=`${categories_a[i].ANSWER_TITLE}`
          }
          var categories_b = b.CATEGORY;
          for(var i = 0; i <categories_b?.length; i++){
            str_b+=`${categories_b[i].ANSWER_TITLE}`
          }
          return `${str_a}`.localeCompare(`${str_b}`)
      }          
      else if(keySelect == 'Booth Number')
        return a.BOOTHS*1 - b.BOOTHS*1;
      else if(keySelect == 'Map Name')
        return  `${a.MAP_NAME}`.localeCompare(`${b.MAP_NAME}`);
      else if(keySelect == 'States')
        return `${a.STATE}`.localeCompare(`${b.STATE}`);
      else if(keySelect == 'Countries')
        return `${a.COUNTRY}`.localeCompare(`${b.COUNTRY}`);
      else if(keySelect == 'Featured Exhibitors')
        return `${a.FEATURED}`.localeCompare(`${b.FEATURED}`);
    }
   
  })

  var pageData = paginate(outputItems, page_size, page);

  console.log("filteredItems", filteredItems)
  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1240, margin:'64px auto', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>
        
        {searchBox()}
        <Box mt={2}>
          <Grid container spacing={2}>
            {
              pageData.map((item, index)=>{
                return(
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <ExhibitorCard item={item} boothFileList={boothFileList} setKeySelect={setKeySelect} setPulldownValue={setPulldownValue} doBookmark={doBookmark}/>
                  </Grid>
                )
              })
            }
            {
              (!outputItems || outputItems.length == 0) && dataFetched && 
              <Box style={{textAlign:'center', width:'100%'}}>
                <p>No Exhibitor Found.</p>
              </Box>
            }
          </Grid>
        </Box>
      </Box>
      <Box mt={3} mb={4}>
        <Stack spacing={2}>
          <Pagination variant="outlined" shape="rounded" style={{justifyContent:'center'}} count={Math.ceil(outputItems.length/page_size)} color="primary"  page={page} onChange={handleChangePage}/>
        </Stack>
      </Box>
      
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setBookmarkList:  data=>dispatch(setBookmarkList(data)),
      setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
      setExhibitorList:  data=>dispatch(setExhibitorList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
