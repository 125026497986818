import React ,{ useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import {List as FixedSizeList } from 'react-virtualized';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { setLeftPage } from '../../../actions/ui'
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { setOpenLogin, setLeftBoothList, setBookmardUpdated, setOpenCompanyProfile, setOpenUnassignedProfile, setOpenBoothList, resetUI } from '../../../actions/ui'
import { setActive3dObject, setBookmarkList, setBoothFileList, resetMain, setActiveMapKey } from '../../../actions/main';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axiosInstance from '../../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import {checkIfSelectable, setActiveObject, highlightObject, deselectHighlightObject} from '../../../utils/CanvasTool'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Fade from '@mui/material/Fade';
import { getTokenPlanner } from '../../../utils/Common'
import { API_URL, S3_URL, ESHOW_URL } from '../../../config.js'
const drawerWidth = 240;



const ColorButton = styled(Button)(({ theme }) => ({
  color: '#7c7c7c',
  minWidth:'55px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#c9c9c980',
  },
}));
var g_leftBoothList = []
var pre_token = null
var highlightedBooths = []
function Index(props) {
  const theme = useTheme();

  useEffect(() => {
    g_leftBoothList = props.ui.leftBoothList
    return () => {
      for(var i = 0; i < highlightedBooths.length; i++){
        var booth = highlightedBooths[i]
        deselectHighlightObject(booth, props.main);
      }
      props.main.mainCanvas.renderAll();
    };
  }, [props.ui.leftBoothList])

  
  const callBookmarkApi= async (exhibitor, value)=>{
    const _token = getTokenPlanner();
    var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
    if(value){
      BOOKMARKS = [
        ...BOOKMARKS,
        {
          COMPANY_NAME: exhibitor?.company?exhibitor?.company:(exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:""),
          NOTES:"",
          STATUS:'',
          BOOTHS:exhibitor.BOOTHS,
          EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
        }
      ]
    }
    else{
      BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
    }
    
    var postData =
    {
      SUCCESS:true,
      BOOKMARKS:BOOKMARKS
    };

    var formdata = {
      query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
      const eshowResponse = res.data;
      if(eshowResponse.SUCCESS == true){
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to save bookmark");
      }
      props.setBookmarkList(BOOKMARKS)
    }).catch(error => {
        console.log("error", error);
    })
  }

  const doBookmark = (exhibitor)=>{
    if(exhibitor){
      const _token = getTokenPlanner();
      if((props.role !='admin') && (!_token || !_token)){
        props.setOpenLogin(true)
        return false;
      }
      var newBoothList = [...props.ui.leftBoothList];
      for(var i =  0; i< newBoothList.length; i++){
        if(`${newBoothList[i]['EXHIBITOR_KEY']}` == `${exhibitor.EXHIBITOR_KEY}`){
          callBookmarkApi(exhibitor, !newBoothList[i]['bookmark'])
          newBoothList[i]['bookmark'] = !newBoothList[i]['bookmark']
        }
      }
      props.setLeftBoothList(newBoothList)
      // props.setBookmardUpdated(true)
    }
  }

  const switchMap = (exhibitor)=>{
    let exhibitor_map_key = exhibitor.MAP_KEY
    var boothFileList = props.main.boothFileList;
    if(props.role == "admin"){
      var tmp = [...boothFileList];
      for(var i = 0; i <tmp.length; i++){
          if(tmp[i]['KEY_ID'] == exhibitor_map_key){
              tmp[i] = {...tmp[i], active:!tmp[i]['active']};
          }
          else{
            tmp[i] = {...tmp[i], active:false}
          }
      }
      deselectHighlightObject(highlightedBooths[i], props.main);
      props.resetMain();
      props.resetUI();
      // props.main.mainCanvas.off('object:moving')
      setTimeout(function(){
        props.setBoothFileList(tmp)
      }, 300)
      return;
    }
    else{
      props.setActiveMapKey(exhibitor_map_key)
      return;
    }
  }
  const onClickItem = (boothParam, exhibitor)=>{
    let exhibitor_map_key = exhibitor.MAP_KEY
    const active_booth_file = props.main.active_booth_file
    if(active_booth_file && active_booth_file.KEY_ID == exhibitor_map_key){
    }
    else{
      switchMap(exhibitor)
      return;
    }    

    if(!boothParam) return;
    var booth = null
    if(typeof boothParam === 'object' && boothParam !== null){
      booth = boothParam
    }
    else{
      var objects = props.main.mainCanvas.getObjects('group');
      for(var i = 0; i < objects.length; i++){
        if(checkIfSelectable(objects[i])){
          if(objects[i].class=="booth" && objects[i].booth_number == boothParam){
            booth = objects[i]
          }
        }
      }
    }

    if(booth && (booth.get('class') == 'booth' || booth.get('class') == 'booth-group')){                            
      var id = booth.id;
      var priced = false
      var priceCheck = booth.get('priceCheck');
      var premiumCheck = booth.get('premiumCheck');
      // if(premiumCheck){
      //     priced = true
      // }
      // if(priceCheck && priceCheck.length > 0){
      //     priced = true
      // }
      if(booth.get('sold')){
          priced = true
      }
      var childObjects = booth.getObjects('rect');
      for(var j = 0; j< childObjects.length; j++){
          if(childObjects[j].get('class') == 'color'){
              childObjects[j].set({fill: 'rgb(213, 5, 48)'})
              props.main.mainCanvas.renderAll();
              break;
          }
      }
      if(priced){
          props.setOpenUnassignedProfile(false)
          props.setOpenCompanyProfile({booth:booth, open:true})
      }
      else{
          props.setOpenCompanyProfile(false)
          props.setOpenUnassignedProfile({booth:booth, open:true})
      }

      

      for(var i = 0; i < highlightedBooths?.length; i++){
        deselectHighlightObject(highlightedBooths[i], props.main);
      }
      props.main.mainCanvas.renderAll();

      var companyBooths = getBoothByCompany(booth.company)
      highlightedBooths = [];
      for(var j = 0; j < companyBooths.length; j++){
        if(companyBooths[j]){
          highlightObject(companyBooths[j], props.main, true);
          highlightedBooths.push(companyBooths[j])
        }
      }
      props.main.mainCanvas.renderAll();

    }
  }

  const getBoothByNumber = (number)=>{
    var objects = props.main.mainCanvas.getObjects('group')
    for(var i = 0; i < objects.length; i++){
      if(checkIfSelectable(objects[i])){
        if(objects[i].class=='booth'){
          if(objects[i].booth_number == number){
            return objects[i]
          }
        }
      }
    }
    return null
  }

  
  const getBoothByCompany = (company)=>{
    var outputs = [];
    var objects = props.main.mainCanvas.getObjects('group')
    for(var i = 0; i < objects.length; i++){
      if(checkIfSelectable(objects[i])){
        if(objects[i].class=='booth'){
          if(objects[i].company == company){
            outputs.push(objects[i])
          }
        }
      }
    }
    return outputs
  }

  const allExhibitorCount = (leftBoothList)=>{
    var count= 0;
    if(leftBoothList){
      count = leftBoothList.length
    }
    return count;
  }

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
    leftBoothList
  }) {
    // var leftBoothList = props.ui.leftBoothList
    if(props.bookmark){
      leftBoothList = leftBoothList.filter((booth)=>{
        return booth.bookmark
      })
    }
    var sales_bookmarks_show = true
    if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
      sales_bookmarks_show = false
    }

    var booth =leftBoothList[index]
    if(booth){
      return (
        <ListItem 
          button 
          key={key} style={style}
          secondaryAction={
            <>
            {booth.booth?.signed_pdf && props.role == "admin" && 
            <IconButton edge="end"  download href={API_URL()+"/content/contract/signature/"+booth.booth?.signed_pdf} >
              {
                <PictureAsPdfIcon/>
              }
            </IconButton>
            }
            {sales_bookmarks_show && 
            <IconButton edge="end" onClick={()=>{ doBookmark(booth)}}>
              {
                booth.bookmark?
                <BookmarkSharpIcon style={{color:'#e54839'}}/>:
                <BookmarkBorderSharpIcon/>
              }
            </IconButton>
           }
            </>
            
          }
        >            
          <ListItemText 
            primary={
              <Box display="flex">
                <Box className="leftPrimaryTxt" style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'55%':'80%'), fontSize:'14px'}}
                  onClick={()=>{onClickItem(getBoothByNumber(booth.booth), booth);}} 
                >
                  {(booth.company?(` ${booth.company}`):"")}
                </Box>
                {props.main.showSetup?.MAP_LOCATION == 'show' &&
                <Box className="leftPrimaryTxt" style={{width:'25%', textAlign:'center'}}
                  onClick={()=>{onClickItem(getBoothByNumber(booth.booth), booth);}} 
                >
                  {(booth.MAP_NAME?(` ${booth.MAP_NAME}`):"")}
                </Box>
                }
                <Box pl={1} style={{width:'20%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                  onClick={
                    ()=>{
                      let exhibitor_map_key = booth.MAP_KEY
                      const active_booth_file = props.main.active_booth_file
                      if(active_booth_file && active_booth_file.KEY_ID == exhibitor_map_key){
                      }
                      else{
                        switchMap(booth)
                        return;
                      } 
                      setActiveObject(getBoothByNumber(booth.booth), props.main, true, props.role == "admin"?false:(props.ui.openBoothList));
                    }
                  } 
                >
                  <b>{(booth.name?booth.name:"")}</b>
                </Box>
              </Box>
            } 
          />
        </ListItem>
      );
    }
  }

    var listHeight = window.innerHeight - 180
    if(props.role == "admin"){
        listHeight = window.innerHeight - 168
    }
    var leftBoothList = props.ui.leftBoothList
    if(props.bookmark){
        leftBoothList = leftBoothList.filter((booth)=>{
        return booth.bookmark
        })
    }

    var exhibitorSearchKey = props.ui.exhibitorSearchKey
    if(exhibitorSearchKey){
        leftBoothList = leftBoothList.filter((booth)=>{
            console.log("booth", booth)
            var str = (booth.company?(` (${booth.company})`):"")+(booth.name?booth.name:"")
            console.log("str", str)
            return (str.toLowerCase().includes(exhibitorSearchKey.toLowerCase()))
        })
    }

    console.log("leftBoothList", leftBoothList)
    const drawerWidth = Math.min(Math.max(410, Math.round(window.innerWidth*0.33)), window.innerWidth);
  
    return (
        <Fade in={true} timeout={1200}>
        <div> 
            <Box className='slideout-header' display="flex"  style={{justifyContent:'space-between', padding:'10px 48px 10px 16px', borderBottom:'1px solid #e0e0e0', background:props.ui?.customStyles?.['left-table-header-bg']?props.ui?.customStyles['left-table-header-bg']:'#4e7090', color:'white', alignItems:'center'}}>
            <Box style={{width: (props.main.showSetup?.MAP_LOCATION == 'show'?'48%':'76%'), textAlign:'left', paddingLeft: '0px'}}><b>{props.title?props.title:'Exhibitor'}</b> <span style={{color:'#a0a0a0',  marginLeft:5}}>({allExhibitorCount(leftBoothList)})</span></Box>
            {props.main.showSetup?.MAP_LOCATION == 'show' &&
            <Box style={{width: '28%', textAlign:'center', paddingLeft: '0px'}}><b>Location</b></Box>
            }
            <Box style={{width: '22%', textAlign:'center', paddingLeft: '0px'}}><b><LocationOnOutlinedIcon/></b></Box>
            </Box>
            {leftBoothList&&
            <FixedSizeList
                width={window.innerWidth >900?(drawerWidth-(props.role == 'admin'?0:72)):(window.innerWidth-40)}
                height={listHeight}
                rowCount={leftBoothList.length}
                rowHeight={49}
                rowRenderer={(param)=>rowRenderer({...param, leftBoothList})}
            >
            </FixedSizeList>
            }
        </div>
        </Fade>
    );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    ui: state.ui,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return { 
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
    setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
    setBookmardUpdated:  data=>dispatch(setBookmardUpdated(data)),
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setOpenUnassignedProfile:  data=>dispatch(setOpenUnassignedProfile(data)),
    setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
    setActive3dObject: value=>dispatch(setActive3dObject(value)) ,
    setBookmarkList:  data=>dispatch(setBookmarkList(data)),
    setBoothFileList: data=>dispatch(setBoothFileList(data)), 
    resetUI: data=>dispatch(resetUI(data)), 
    resetMain: data=>dispatch(resetMain(data)),
    setActiveMapKey: value=>dispatch(setActiveMapKey(value)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
