import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers, setPlanner } from '../../actions/main'
import { setOpenLogin, setOtpFlag, setOpenSignup, setOpenResetPassword, setSnackMsg, setOpenSnack,setBookmardUpdated, setOpenNewPassword } from '../../actions/ui'
import { NavLink } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import { parseTokenValue } from '../../utils/Common'
import { getTokenCookie, setTokenPlanner } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'

import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { API_URL, S3_URL, ESHOW_URL } from '../../config.js'

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function Index(props) {
  const { onClose, onOk, onChange, open } = props;
  var mainLayers = props.main.mainLayers;
  const [formValue, setFormValue] = React.useState({});
  const [processing, setProcessing] = React.useState(false);
  let query = useQuery();

  useEffect(() => {
  }, [])

  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }
  
  const handleSignin = (e)=>{
    e.preventDefault();
    // if(!formValue.password){
    //   props.setOpenSnack(true);
    //   props.setSnackMsg("Invalid password");
    //   return false;
    // }
    setProcessing(true)
    var postData = 
    {
      EMAIL: formValue.email?formValue.email:'',
      PASSWORD: formValue.password?formValue.password:''
    }
    var formdata = {
      query:`${ESHOW_URL()}?method=putLogin&token=${getTokenCookie()}`,
      postData: postData,
      returnResponse: true
    }
    var data = JSON.stringify(
      postData
    );
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putLogin&token=${getTokenCookie()}`
    // var url = `/postCrm1`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
            
          var tokenInfo = await parseTokenValue(eshowResponse.TOKEN)
          setTokenPlanner(eshowResponse.TOKEN, tokenInfo)
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Successfully logged in");
          props.setOpenLogin(false)
          props.setOtpFlag(false)
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          var show_key = urlParams.get('show_key')  
          props.setPlanner({tokenInfo, show_key})
          if(eshowResponse.OTP == true){
            props.setOpenNewPassword(true)
          }
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to login");
        }
        setProcessing(false)
        
    }).catch(error => {
        console.log("error", error);
    })
  }


  const onChangeName = (e)=>{
    onChange(e)
  }
  return (
    <Dialog
      className='top-modal'
      fullWidth={true}
      maxWidth={"sm"} onClose={()=>{props.setOpenLogin(false);  props.setOtpFlag(false)}} open={props.ui.openLogin}>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        <Box style={{}}>
          <IconButton style={{float:'right', padding:5}} color="inherit" onClick={()=>{props.setOpenLogin(false); props.setOtpFlag(false)}} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom style={{textAlign: 'center', fontSize: '25px',color: '#1565c0'}}>
            Sign In
          </Typography>
          {props.ui.otpFlag &&
          <Typography variant="h5" gutterBottom style={{textAlign: 'center', fontSize: '20px',color: 'red'}}>
            Alert: Enter OTP code send to your email to reset password
          </Typography>
          }
        </Box>
        <form onSubmit={handleSignin}>
          <TextField
            name="email"
            margin="dense"
            label="Email"
            type="name"
            fullWidth
            variant="standard"
            onChange={onChangeValue}
          />
          <TextField
            name="password"
            margin="dense"
            label={props.ui.otpFlag?"OTP Code":"Password"}
            type="password"
            fullWidth
            variant="standard"
            onChange={onChangeValue}
          />
          <Box mt={2} display='flex' style={{justifyContent:'space-between'}}>
            <Button size="small" className="buttonLink" onClick={()=>{props.setOpenSignup(true); props.setOpenLogin(false); props.setOtpFlag(false)}}>
              Create new planner
            </Button>
            <Button size="small" onClick={()=>{props.setOpenResetPassword(true); props.setOpenLogin(false); props.setOtpFlag(false)}}  className="buttonLink">Forgot Password</Button>
          </Box>
          <Box mt={2} textAlign='center' sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Button variant="contained" color="primary" size="large" type="submit">
              Sign in
            </Button>
            {processing &&
            <CircularProgress 
              sx={{
              marginLeft:'10px'
              }}
              size={25}
              thickness={4}
            />
            }
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)) ,
        setOpenLogin: data=>dispatch(setOpenLogin(data)) ,
        setOtpFlag: data=>dispatch(setOtpFlag(data)) ,
        setOpenSignup: data=>dispatch(setOpenSignup(data)),
        setOpenResetPassword: data=>dispatch(setOpenResetPassword(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),
        setBookmardUpdated:  data=>dispatch(setBookmardUpdated(data)),
        setPlanner: data=>dispatch(setPlanner(data)) ,
        setOpenNewPassword: data=>dispatch(setOpenNewPassword(data)) ,
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);