import { combineReducers } from 'redux';
import main from './main';
import booth_history from './booth_history';
import ui from './ui';
import element from './element';
import log from './log';
import cart from './cart';
import local_storage from './local_storage'
import { connectRouter } from 'connected-react-router';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    main, booth_history, ui, element, log, cart, local_storage
});
