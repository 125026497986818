
import Box from '@mui/material/Box';
import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'

function Index(props) {
    return(
        <Box style={{marginTop: 50}}>
            <h2 style={{color: '#C54122', marginBottom: 0}}>403</h2>
            <h1 style={{color: '#C54122', marginTop: 10, fontSize: 27}}>ACCESS DENIED</h1>
            <p style={{color: '#ED5519', fontSize: 18}}>
                Sorry, You don't have permission to access this page
            </p>
            <img style={{marginTop: 30}} width='300px' src="/asset/login-form-eshow-logo.jpg"/>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(Index);