import React ,{ useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import MainViewer from './viewer/MainViewer';
import MainViewer3D from './viewer3d/MainViewer';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToolBox from './viewer/ToolBox';
import AppLeftPanel from './viewer/component/AppLeftPanel';
import { setAuthInfo, setLoading} from './actions/main'
import { parseToken, getTokenCookie, getTokenPlanner, setCookie } from './utils/Common'
import { setCustomStyles } from './actions/ui'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import qs, { parse } from 'qs'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {List as FixedSizeList } from 'react-virtualized';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setOpenLogin, setOpenSignup, setOpenResetPassword, setSnackMsg, setOpenSnack,setBookmardUpdated, setOpenNewPassword } from './actions/ui'
import { useParams } from 'react-router-dom'
import { API_URL, ESHOW_URL } from './config.js'

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [formValue, setFormValue] = React.useState({});
  const [bookmarks, setBookmarks] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const processAuth = async ()=>{    
    var tokenInfo = await parseToken(query)     
    await fetchCustomStyles();         
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, SALES,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        props.setLoading(false)  
        return
    }
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url = `${ESHOW_URL()}?method=getCSS`
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    const response = await axios.get(url, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const fetchData = ()=>{
    props.setLoading(true)
    var category_key = props.ui.leftSelectedCategory.KEY_ID
    const _token = getTokenPlanner();
    var url = `${ESHOW_URL()}?method=getPlanner`
    
    const options = {
      headers: {
        'Authorization': `Bearer ${_token}`
      }
    } 
    axios.get(url, options).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
          if(result.PLANNER){
            var tmp = {...result.PLANNER}
            tmp.PASSWORD = ""
            setFormValue(result.PLANNER)
            setBookmarks(result.BOOKMARKS)
          }
        }
        else{
          setFormValue({})
        }
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    // if(props.urlToken){
    //     var public_token = `/${id1}/${id2}/${id3}`
    //     setCookie('V3PUBLICMAP', public_token+(id4?`/${id4}`:''))
    // }
    // processAuth()
    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    fetchData()
  }, [id5, id4, id3, id2, id1])

  const saveForm=()=>{

  }

  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    var postData = 
    {
      COMPANY_NAME: formValue.COMPANY_NAME?formValue.COMPANY_NAME:'',
      CITY: formValue.CITY?formValue.CITY:'',
      COUNTRY: formValue.COUNTRY?formValue.COUNTRY:'',
      FIRST_NAME: formValue.FIRST_NAME?formValue.FIRST_NAME:'',
      LAST_NAME: formValue.LAST_NAME?formValue.LAST_NAME:'',
      TITLE: formValue.TITLE?formValue.TITLE:'',
      EMAIL: formValue.EMAIL?formValue.EMAIL:'',
      ZIP_CODE: formValue.ZIP_CODE?formValue.ZIP_CODE:'',
      PHONE: formValue.PHONE?formValue.PHONE:'',
      STATE: formValue.STATE?formValue.STATE:'',
      KEY_ID: formValue.KEY_ID,
      ADDRESS1: formValue.ADDRESS1?formValue.ADDRESS1:'',
      ADDRESS2: formValue.ADDRESS2?formValue.ADDRESS2:''
    }
    const _token = getTokenPlanner();
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_token}`
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner`
    axios.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS == true){
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Successfully updated");
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to update");
      }
      // props.setViewFile(filename);
    }).catch(error => {
        console.log("error", error);
    })
  }

  const handleSubmitPassword = (e)=>{
    e.preventDefault();
    
    if(!formValue.PASSWORD || formValue.PASSWORD==""||!formValue.CURRENT_PASSWORD || formValue.CURRENT_PASSWORD==""){
      props.setOpenSnack(true);
      props.setSnackMsg("Invalid password");
      return false;
    }
    if(formValue.PASSWORD && formValue.PASSWORD!="" && formValue.PASSWORD != formValue.confirm_password){
      props.setOpenSnack(true);
      props.setSnackMsg("Invalid password");
      return false;
    }
    var postData = 
    {
    }
    postData.PASSWORD = formValue.PASSWORD
    postData.CURRENT_PASSWORD = formValue.CURRENT_PASSWORD
    const _token = getTokenPlanner();
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_token}`
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner`
    axios.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS == true){
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Successfully updated");
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to update");
      }
      // props.setViewFile(filename);
    }).catch(error => {
        console.log("error", error);
    })
  }

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {

    var bookmark =bookmarks[index]
    if(bookmark){
      return (
        <ListItem 
          button 
          key={key} style={style}
          secondaryAction={
            <>
            {/* {booth.booth?.signed_pdf && props.role == "admin" && 
            <IconButton edge="end"  download href={API_URL()+"/content/contract/signature/"+booth.booth?.signed_pdf} >
              {
                <PictureAsPdfIcon/>
              }
            </IconButton>
            }
            <IconButton edge="end" onClick={()=>{ doBookmark(booth.booth)}}>
              {
                booth.bookmark?
                <BookmarkSharpIcon style={{color:'#e54839'}}/>:
                <BookmarkBorderSharpIcon/>
              }
            </IconButton> */}
            
            </>
            
          }
        >            
          <ListItemText 
            primary={
              <Box display="flex">
                <Box className="leftPrimaryTxt" style={{width:'80%'}}
                >
                  {(bookmark.COMPANY_NAME?(` ${bookmark.COMPANY_NAME}`):"")}
                </Box>
                <Box pl={1} style={{width:'20%', fontSize:14.5, color: "#505050"}}
                >
                  <b>{(bookmark.BOOTHS?bookmark.BOOTHS:"")}</b>
                </Box>
              </Box>
            } 
          />
        </ListItem>
      );
    }
  }
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  var listHeight = 300

  console.log("render client app")
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  return (
    <div id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box style={{maxWidth: 1200, margin:'64px auto', paddingTop: 60, marginTop:(banner && banner != ''?(150+64):64)}}>
        <Paper  elevation={2} style={{padding:'20px'}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={<b>My Profile</b>} {...a11yProps(0)} />
              <Tab label={<b>Bookmarks</b>} {...a11yProps(1)} />
              <Tab label={<b>Password</b>} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <form style={{padding:15}} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>        
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="COMPANY_NAME"
                    margin="dense"
                    label="Company"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.COMPANY_NAME?formValue.COMPANY_NAME:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                  
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="EMAIL"
                    margin="dense"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.FIRST_NAME?formValue.FIRST_NAME:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="FIRST_NAME"
                    margin="dense"
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.FIRST_NAME?formValue.FIRST_NAME:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="LAST_NAME"
                    margin="dense"
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.LAST_NAME?formValue.LAST_NAME:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>                   
                <Grid item xs={12} sm={6}>    
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="TITLE"
                    margin="dense"
                    label="Title"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={formValue.TITLE?formValue.TITLE:""}
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12}>      
                  <Box mt={2} textAlign='right'>
                    <Button variant="contained" color="primary" size="large" type="submit">
                      Update Planner
                    </Button>
                  </Box>
                </Grid> 
              </Grid>   
            </form>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>  
                <Box mt={4}>
                  <Box display="flex"  style={{justifyContent:'space-between', padding:'10px 16px', borderBottom:'1px solid #e0e0e0', background:props.ui?.customStyles?.['left-table-header-bg']?props.ui?.customStyles['left-table-header-bg']:'#4e7090', color:'white', alignItems:'center'}}>
                    <Box style={{width: '70%', textAlign:'left', paddingLeft: '1rem'}}><b>Exhibitor</b></Box>
                    <Box style={{width: '30%', textAlign:'left', paddingLeft: '12px'}}><b><LocationOnOutlinedIcon/></b></Box>
                  </Box>
                  <Box style={{maxHeight:'400px', overflowY:'auto', borderBottom:'1px solid #e0e0e0', borderLeft:'1px solid #e0e0e0', borderRight:'1px solid #e0e0e0'}}>
                    {bookmarks&&
                      <>
                        {
                          bookmarks.map((bookmark, key)=>{
                            return(
                              <Box key={key} display="flex"  style={{justifyContent:'space-between', padding:'10px 16px',alignItems:'center'}}>
                                <Box style={{width: '70%', textAlign:'left', paddingLeft: '1rem'}}>{(bookmark.COMPANY_NAME?(` ${bookmark.COMPANY_NAME}`):"")}</Box>
                                <Box style={{width: '30%', textAlign:'left', paddingLeft: '12px'}}>{(bookmark.BOOTHS?bookmark.BOOTHS:"")}</Box>
                              </Box>                              
                            )
                          })
                        }
                      </>
                    }
                  </Box>
                </Box>
              </Grid>    
            </Grid>   
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <form onSubmit={handleSubmitPassword} style={{maxWidth: 650, margin:'auto', padding:15}}>
              <Grid container spacing={2}>                
              <Grid item xs={12} sm={12}>        
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="CURRENT_PASSWORD"
                    margin="dense"
                    label="Current Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={onChangeValue}
                    autoComplete="new-password"
                    autocomplete="new-password"
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={12}>        
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="PASSWORD"
                    margin="dense"
                    label="New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={onChangeValue}
                    autoComplete="new-password"
                    autocomplete="new-password"
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12} sm={12}>       
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="confirm_password"
                    margin="dense"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    onChange={onChangeValue}
                    size="small"
                  />
                </Grid>   
                <Grid item xs={12}>      
                  <Box mt={2} textAlign='right'>
                    <Button variant="contained" color="primary" size="large" type="submit">
                      Update Password
                    </Button>
                  </Box>
                </Grid> 
              </Grid>   
            </form>
          </TabPanel>
        </Paper>
        {props.ui?.customStyles?.['loaded'] &&
        <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay>
        } 
      </Box>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setSnackMsg: data=>dispatch(setSnackMsg(data)),
      setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
